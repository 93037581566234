import React from 'react';
import { observer } from 'mobx-react';

import { Item, Product } from '../../../../types';
import PreviewStore from '../../../../stores/look-builder/PreviewStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import CategoryIcon from '../../../shared/components/CategoryIcon';
import { prettifyCategory } from '../../../utils/utils';
import PreviewLayerThumbnails from '../preview/PreviewLayerThumbnails';
import IconTrashcan from '../../../../components/IconTrashcan';
import IconPlus from '../../../../components/IconPlus';

interface Props {
  category: string;
  product: Item | Product | null;
  setAnimateDirection?: React.Dispatch<React.SetStateAction<string>>;
}

const ListItem = (props: Props) => {
  const { product } = props;
  const name = product && product.displayName;
  const price = product && product.cost;
  const isBundle = product && product.category === 'preconfigured';
  const isBuyable = product && (product.type === 'Purchase' || product.isRetail);

  return (
    <div className="group relative z-10 mb-4 cursor-pointer " onTouchStart={() => {}}>
      <div
        className={`grid w-full items-stretch transition ease-out noMouse:active:scale-[.95] mouse:group-hover:scale-[.98] mouse:group-hover:bg-brand-lighter mouse:group-hover:shadow-gallery-item ${
          name ? 'bg-white' : 'bg-gray-lighter'
        } tracker-btn-look_builder-list_item-${props.category}-20200508-111100`}
        style={{ gridTemplateColumns: 'auto 1fr' }}
      >
        <button
          aria-label={name ? `Edit ${name}` : `Add ${prettifyCategory(props.category)}`}
          title={name ? `Edit ${name}` : `Add ${prettifyCategory(props.category)}`}
          className="absolute inset-0 z-40 w-full"
          onClick={() => {
            props.setAnimateDirection && props.setAnimateDirection('left');
            window.scrollTo(0, 0);
            SideCarStore.set({
              car: 'gallery',
              category: props.category,
              id: null,
            });
          }}
        />
        <div className="relative flex min-h-[64px] w-64 items-center justify-center border-r border-black border-opacity-10 py-4">
          {!name ? (
            <CategoryIcon className="!text-brand-darker" category={props.category} />
          ) : (
            <PreviewLayerThumbnails
              product={
                isBundle && PreviewStore.bundle
                  ? PreviewStore.bundle.products!.filter((p) => !p.category!.includes('Pant'))[0]
                  : props.product!
              }
            />
          )}
        </div>

        <div className="flex flex-col justify-center overflow-auto px-16">
          <div className="flex items-center justify-between py-8">
            {name ? (
              <p className="text-xs overflow-hidden text-ellipsis whitespace-nowrap text-gray-darker">
                <span className="mr-4 inline">{name}</span>
                <span className="hidden sm:inline">
                  <span className="text-xs text-brand-darker">Edit</span>
                </span>
              </p>
            ) : (
              <h6 className="text-h6 text-brand-darker">{prettifyCategory(props.category)}</h6>
            )}

            {price !== null ? (
              <button
                className={`z-50 grid cursor-pointer place-items-center p-8 transition hover:bg-white hover:shadow-lg tracker-btn-look_builder-unset_${props.category}-20200508-111100 trashcan`}
                title={`Remove ${prettifyCategory(props.category)}`}
                onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
                  PreviewStore.unsetActiveItem(props.category);
                  e.stopPropagation();
                }}
                aria-label="Delete"
              >
                <IconTrashcan className="pointer-events-none" />
              </button>
            ) : (
              <button
                tabIndex={-1}
                className={`z-50 grid cursor-pointer place-items-center p-8 transition hover:bg-white hover:shadow-lg tracker-btn-look_builder-list_item_plus-${props.category}-20200508-111100`}
                onClick={() => {
                  window.scrollTo(0, 0);
                  SideCarStore.set({
                    car: 'gallery',
                    category: props.category,
                    id: null,
                  });
                }}
                aria-label={name ? `Edit ${name}` : `Add ${prettifyCategory(props.category)}`}
                title={name ? `Edit ${name}` : `Add ${prettifyCategory(props.category)}`}
              >
                <IconPlus className="pointer-events-none text-brand-darker" />
              </button>
            )}
          </div>
          {name && (
            <>
              <hr className="text-black opacity-[0.05] group-hover:opacity-[0.05]" />

              <div className="flex justify-between py-8">
                <p className="text-xs text-gray-dark">
                  {isBuyable ? 'Purchase' : 'Rental'}
                  &nbsp;Price:
                </p>
                <p className="text-xs mr-4 text-gray-dark">${price}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ListItem);
