import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Elements } from 'react-stripe-elements';

import { IsEventMember } from '../../utils/HOC';
import {
  formatCart,
  getAttendees,
  getItemsCost,
  getTotalDiscount,
  getShippingCost,
  getDamageFee,
  getMemberCartTotal,
  getCurrentMember,
  getOtherMembers,
  getAttendeesWithNonEmptyCart,
  getUnPaidMembers,
} from '../../utils/checkout';
import FormFLowLogo from '../../utils/Component/FormFlowLogo';
import { getStringCookie, formatCurrency } from '../../utils/utils';
import Flow from '../../utils/HOC/Flow';

import {
  Member,
  GTEvent,
  GlobalContextTyping,
  MemberOrderDetails,
  FlowEventRouteProps,
  CheckoutRequest,
} from '../../types';

import { getCheckoutEventDetails } from '../../services/Events';
import { orderDetails, checkout, validatePromos } from '../../services/Payments';

import AddressesStore from '../../stores/AddressesStore';
import MemberStore from '../../stores/MemberStore';
import CheckoutStore from '../../stores/CheckoutStore';

import ProductList from '../../invited-member-flow/components/ProductList';
import CardInfo from './CardInfo';
import Spinner from './Spinner';
import Message from '../../components/Message';
import FormCheckItem from '../../components/FormCheckItem';
import FormInput from '../../components/FormInput';
import AnimateHeight from '../../components/AnimateHeight';
import IconTriangleDown from '../../components/IconTriangleDown';
import { Disclosure } from '@headlessui/react';
import DottedRow from '../../components/DottedRow';
import { orderPlaced } from '../../utils/metrics';
import auth from '../../services/Auth';

const getMemberToggleKey = (member: Member) => {
  const customerId = member.accountId ?? member.customer?.id ?? new Date().getTime();
  const memberId = member.id ?? new Date().getTime();

  return `${customerId}-${memberId}`;
};

interface MemberToggleProps {
  member: Member;
  editable: boolean;
  memberIsSelected?: (member: Member) => boolean;
  handleMemberSelectionChange?: (member: Member, checked: boolean) => void;
}

const MemberToggle = ({ member, editable, memberIsSelected, handleMemberSelectionChange }: MemberToggleProps) => (
  <div className="border border-gray-light bg-gray-lighter">
    <div className="flex items-stretch">
      <div className="flex items-stretch border-r border-gray-light bg-white">
        <FormCheckItem
          name={getMemberToggleKey(member)}
          className="mx-0 !grid-cols-none place-items-center px-16"
          checked={editable ? memberIsSelected && memberIsSelected(member) : true}
          disabled={!editable}
          disabledMessage={''}
          onChange={(e) =>
            editable ? handleMemberSelectionChange && handleMemberSelectionChange(member, e.target.checked) : undefined
          }
        />
      </div>

      <Disclosure as="div">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`group flex w-full p-16 ${
                (memberIsSelected && memberIsSelected(member)) || !editable ? 'bg-white' : ''
              }`}
            >
              <div className="flex grow items-center justify-between">
                <h5 className={`text-h5 ${memberIsSelected && memberIsSelected(member) ? '' : 'text-gray-dark'}`}>{`${
                  member.customer!.firstName
                } ${member.customer!.lastName}`}</h5>

                <div className="flex items-center gap-8">
                  <p
                    className={`${
                      (memberIsSelected && memberIsSelected(member)) || !editable ? '' : 'text-gray-dark line-through'
                    }`}
                  >
                    {formatCurrency(getMemberCartTotal(member))}
                  </p>

                  <IconTriangleDown
                    className={`fill-current transition duration-150 group-hover:text-brand-darker ${
                      open ? '' : 'rotate-90 transform'
                    }`}
                  />
                </div>
              </div>
            </Disclosure.Button>

            <AnimateHeight open={open}>
              <Disclosure.Panel
                static
                className={`pb-16 ${(memberIsSelected && memberIsSelected(member)) || !editable ? 'bg-white' : ''}`}
              >
                <div className="border-t border-gray-light px-16">
                  <ProductList products={member.productToMember!} />
                </div>
              </Disclosure.Panel>
            </AnimateHeight>
          </>
        )}
      </Disclosure>
    </div>
  </div>
);

interface Props extends FlowEventRouteProps<{}> {
  globalContext?: GlobalContextTyping;
}

interface State {
  loadingEvent: boolean;
  loadingDetails: boolean;
  fetchingOrderDetails: boolean;
  event?: GTEvent;
  currentMember?: Member;
  membersToPayFor: Member[];
  error?: string;
  rushCost: number;
  memberOrderDetails?: MemberOrderDetails;
  token: string;
  promoCodes: string;
  appliedPromos: string;
  applyingPromo: boolean;
  applyPromoSuccess: boolean;
  applyPromoError: string;
}

class Checkout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      event: undefined,
      currentMember: undefined,
      membersToPayFor: [],
      loadingEvent: true,
      loadingDetails: true,
      error: '',
      fetchingOrderDetails: true,
      rushCost: 0,
      memberOrderDetails: {},
      token: '',
      promoCodes: '',
      appliedPromos: '',
      applyingPromo: false,
      applyPromoSuccess: false,
      applyPromoError: '',
    };
  }

  async componentDidMount() {
    if (AddressesStore.currentAddress.id === undefined) {
      if (this.props.history.location.pathname.includes('/event/')) {
        return this.props.history.push(`/event/shipping${this.props.location.search}`);
      }

      return this.props.history.push(`/invited/shipping${this.props.location.search}`);
    }

    try {
      const res = await getCheckoutEventDetails(this.props.eventId);
      const response = (await res.json()).data;
      const event = {
        ...response.event,
        members: formatCart(response.event.members),
      };
      const currentMember = getCurrentMember(event.members);
      const attendees = getAttendees(event.members);
      const unPaidMembers = getUnPaidMembers(attendees);

      if (this.props.history.location.pathname.includes('/invited/') && currentMember!.isPaid) {
        return this.props.history.push(`/invited/completed${this.props.location.search}`);
      }

      if (unPaidMembers.length === 0) {
        return this.props.history.push(`/event-flow/checkout${this.props.location.search}`);
      }

      this.setState(
        {
          event,
          currentMember,
          membersToPayFor: unPaidMembers.filter((unPaidMember) => currentMember!.id === unPaidMember.id),
          loadingEvent: false,
        },
        this.getAndSetOrderDetails
      );
    } catch (e) {
      this.setState({
        error: 'We ran into an error retrieving your event details. Please contact our customer service team for help.',
      });
    }
  }

  async getAndSetOrderDetails() {
    if (this.state.membersToPayFor.length === 0) {
      this.setState({
        loadingDetails: false,
        fetchingOrderDetails: false,
      });
      return;
    }

    try {
      const r = await orderDetails(this.state.event!, this.state.membersToPayFor);

      const res = await r.json();
      this.setState({
        memberOrderDetails: res.members,
        rushCost: res.rush_cost ? res.rush_cost : 0,
        loadingDetails: false,
        fetchingOrderDetails: false,
      });
    } catch (e) {
      this.setState({
        error: 'We ran into an error retrieving your order details. Please contact our customer service team for help.',
      });
    }
  }

  handlePromoChange(value: string) {
    this.setState({
      promoCodes: value.replace(/\s+/g, ''),
    });
  }

  applyPromo() {
    this.setState({
      applyingPromo: true,
      applyPromoSuccess: false,
      applyPromoError: '',
    });

    this.validatePromos();
  }

  async validatePromos() {
    const r = await validatePromos(this.state.event!, this.state.promoCodes, this.state.membersToPayFor);
    try {
      const response = await r.json();

      if (r.status !== 200) {
        throw new Error(response.heading.error);
      }

      // Create new memberOrderDetails object by
      // combining validatePromos object and current
      // memberOrderDetails object in state.
      const memberOrderDetails = Object.keys(response).reduce((acc, key) => {
        const memberOrderDetails = {
          ...this.state.memberOrderDetails![key],
          discount: response[key].discount,
          damage_waiver_fee: response[key].damage_waiver_fee,
        };

        return {
          ...acc,
          [key]: memberOrderDetails,
        };
      }, {});

      this.setState((state) => ({
        memberOrderDetails: memberOrderDetails,
        appliedPromos: state.promoCodes,
        applyingPromo: false,
        applyPromoSuccess: true,
        applyPromoError: '',
      }));
    } catch (e) {
      let errorMessage = 'Failed to validate promo.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      this.setState(() => ({
        applyingPromo: false,
        applyPromoSuccess: false,
        applyPromoError: errorMessage,
      }));
    }
  }

  /**
   * After a member selection change, we want to get and set orderDetails.
   */
  handleMemberSelectionChange = async (member: Member, checked: boolean) => {
    this.setState(
      (state) => ({
        membersToPayFor: checked
          ? state.membersToPayFor.filter((memberToPayFor) => memberToPayFor.id !== member.id).concat(member)
          : state.membersToPayFor.filter((memberToPayFor) => memberToPayFor.id !== member.id),
      }),
      async () => {
        // When selecing other members, we need to refetch
        // order details and consequently refetch promotion
        // validation, if there are any promotions.
        await this.getAndSetOrderDetails();
        if (this.state.membersToPayFor.length === 0) {
          this.setState({
            applyPromoSuccess: false,
            applyPromoError: '',
            appliedPromos: '',
          });
        }
        if (this.state.promoCodes.length > 0 && this.state.membersToPayFor.length > 0) {
          this.validatePromos();
        }
      }
    );
  };

  getMetaData = () => {
    const metadata = JSON.stringify(
      getStringCookie('iterableEmailCampaignId') === ''
        ? undefined
        : JSON.stringify({
            campaignId: getStringCookie('iterableEmailCampaignId'),
            templateId: getStringCookie('iterableTemplateId'),
            messageId: getStringCookie('iterableMessageId'),
          })
    );
    return metadata !== '{}' ? metadata : undefined;
  };

  sendCheckoutRequest = async () => {
    try {
      if (this.state.event && this.state.currentMember && this.state.token) {
        const res = await checkout(
          this.state.membersToPayFor.reduce((acc, member, i) => ({ ...acc, [`memberId-${i}`]: member.id }), {
            accountId: window.gt.user.id,
            eventId: this.state.event.id!,
            eventDate: this.state.event.startDate!,
            shipFirstName: AddressesStore.currentAddress.firstName,
            shipLastName: AddressesStore.currentAddress.lastName,
            shipAddressLine1: AddressesStore.currentAddress.addressLine1,
            shipAddressLine2: '',
            shipAddressCity: AddressesStore.currentAddress.city,
            shipAddressState: AddressesStore.currentAddress.state,
            shipAddressZip: AddressesStore.currentAddress.zip,
            paymentToken: this.state.token,
            customInviteMsg: '',
            promoCode: this.state.appliedPromos,
            saveShipAddress: true,
            rushCost: this.state.membersToPayFor.length * this.state.rushCost,
            waiveDamageWaiverFee: false,
            orderMetaData: this.getMetaData(),
          } as CheckoutRequest)
        );

        const data = await res.json();

        if (data.heading.status === 'failure') {
          throw Error(data.heading.error);
        }

        return data;
      }
    } catch (e) {
      let errorMessage = 'Failed to send checkout request.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      throw Error(errorMessage);
    }
  };

  handleTokenSuccess = async (token: string) => {
    this.setState({ token });
    try {
      const data = await this.sendCheckoutRequest();

      CheckoutStore.setResponse({
        ...data,
        chargeDate: data.chargeDateTime,
        rentalCost: getItemsCost(this.state.membersToPayFor),
      });

      this.state.membersToPayFor.forEach((m) => MemberStore.updateIsPaid(m.id!, true));

      const { eventId, orderId } = CheckoutStore.response;

      try {
        orderPlaced(auth.user(), eventId, orderId);
      } catch (e) {
        console.error(e);
      } finally {
        this.nextPage();
      }
    } catch (e) {
      let errorMessage = 'Failed to send checkout request token.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      this.setState({ error: errorMessage });
      console.error(e);
    }
  };

  handleTokenError = (error: string) => this.setState({ error });

  getSubTotal = () =>
    getItemsCost(this.state.membersToPayFor) -
    getTotalDiscount(this.state.membersToPayFor, this.state.memberOrderDetails!);

  getTotal = () => {
    let total =
      this.getSubTotal() +
      getShippingCost(this.state.membersToPayFor, this.state.memberOrderDetails!) +
      getDamageFee(this.state.membersToPayFor, this.state.memberOrderDetails!);
    if (this.state.rushCost) {
      total += this.state.membersToPayFor.length * this.state.rushCost;
    }
    return total;
  };

  memberIsSelected = (member: Member) =>
    typeof this.state.membersToPayFor.find((memberToPayFor) => memberToPayFor.id === member!.id) !== 'undefined';

  nextPage = () => {
    this.setState({
      error: undefined,
    });

    this.props.flow!(this.props.location.search);
  };

  render() {
    const { fetchingOrderDetails, event, currentMember, membersToPayFor, memberOrderDetails } = this.state;

    if (fetchingOrderDetails) {
      return (
        <div className="col-span-12 py-32">
          <Spinner type="minimal" />
        </div>
      );
    }

    const otherMembers =
      Object.keys(event!).length !== 0
        ? currentMember!.isOwner
          ? getOtherMembers(getAttendeesWithNonEmptyCart(event!.members!))
          : []
        : [];

    const shippingCost = getShippingCost(membersToPayFor, memberOrderDetails!);
    return (
      <>
        <FormFLowLogo />

        <div data-testid="shared-checkout" className="container">
          <div className="row">
            <div className="col-span-12 space-y-32 sm:col-span-8 sm:col-start-3 lg:col-span-6 lg:col-start-4">
              <div className="space-y-32">
                <h2 className="text-h2-display">Checkout</h2>

                <div className="space-y-16">
                  <h4 className="text-h3">Who Are You Paying For?</h4>

                  {currentMember && getMemberCartTotal(currentMember) > 0 && (
                    <div className="space-y-8">
                      <h3 className="text-h5 text-gray-dark">You</h3>

                      <MemberToggle member={currentMember} editable={false} />
                    </div>
                  )}

                  {currentMember?.isOwner ? (
                    otherMembers.length > 0 && (
                      <div className="space-y-8">
                        <h3 className="text-h5 text-gray-dark">Event Members</h3>

                        {otherMembers.map((member, i) => (
                          <MemberToggle
                            key={i}
                            member={member}
                            editable={true}
                            memberIsSelected={this.memberIsSelected}
                            handleMemberSelectionChange={this.handleMemberSelectionChange}
                          />
                        ))}
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="border border-gray-light">
                <div className="space-y-16 p-32">
                  <DottedRow title="Items" value={`${formatCurrency(getItemsCost(this.state.membersToPayFor))}`} />

                  <DottedRow
                    title="Discounts"
                    value={`${formatCurrency(
                      getTotalDiscount(this.state.membersToPayFor, this.state.memberOrderDetails!)
                    )}`}
                  />

                  <DottedRow title="Subtotal" value={`${formatCurrency(this.getSubTotal())}`} />

                  <DottedRow title="Shipping" value={`${shippingCost === 0 ? 'FREE' : formatCurrency(shippingCost)}`} />

                  <DottedRow
                    title="Damage Waiver Fees"
                    titleDesc="Non-refundable fee for rental garments that covers inspection, minor repair,
                                  and cleaning related to normal use. This does not cover unrepairable damage."
                    value={`${formatCurrency(
                      getDamageFee(this.state.membersToPayFor, this.state.memberOrderDetails!)
                    )}`}
                  />

                  {this.state.rushCost > 0 && (
                    <DottedRow
                      title="Rush Shipping and Processing"
                      titleDesc="Rush shipping and processing are required for events less than 16 days away."
                      value={`${formatCurrency(this.state.membersToPayFor.length * this.state.rushCost)}`}
                    />
                  )}

                  <DottedRow title="Estimated Tax" value="TBD" />
                </div>

                <DottedRow
                  title="Total"
                  value={`${formatCurrency(this.getTotal())}`}
                  className="border-b border-t border-gray-light p-32"
                />

                <Disclosure as="div" className="" id="order-details">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className={`group} flex w-full p-32`}>
                        <p className="text-sm text-brand-darker">Have a promo code?</p>
                      </Disclosure.Button>

                      <AnimateHeight open={open}>
                        <Disclosure.Panel static className={`px-32`}>
                          <div className="space-y-16 pb-32">
                            <div className="flex grow">
                              <FormInput
                                id="promoCode"
                                name="promo code"
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  this.handlePromoChange(e.target.value)
                                }
                                placeholder="promo code"
                                value={this.state.promoCodes}
                                className="grow"
                              />

                              <button
                                className="tracker-button-checkout-promo_apply-200619-111519 btn btn-info"
                                disabled={this.state.applyingPromo}
                                onClick={() => this.applyPromo()}
                              >
                                Apply
                              </button>
                            </div>

                            <p className="text-xs text-gray-dark">
                              Multiple promo codes can be used if separated by comma (i.e. CODE1,CODE2)
                            </p>

                            {this.state.appliedPromos.length > 0 && (
                              <p className="text-sm">
                                <span className="text-sm text-gray-dark">Applied Codes:</span>
                                {this.state.appliedPromos.split(',').map((promo) => (
                                  <span key={promo} className="text-sm">
                                    {promo}
                                  </span>
                                ))}
                              </p>
                            )}

                            {this.state.applyPromoError && (
                              <Message type="error" message={this.state.applyPromoError} />
                            )}
                            {this.state.applyPromoSuccess && <Message type="success" message={'Code accepted.'} />}
                          </div>
                        </Disclosure.Panel>
                      </AnimateHeight>
                    </>
                  )}
                </Disclosure>
              </div>

              <Elements>
                <CardInfo
                  handleTokenSuccess={this.handleTokenSuccess}
                  handleTokenError={this.handleTokenError}
                  back={this.props.history.goBack}
                  orderType={'checkout'}
                  membersToPayFor={this.state.membersToPayFor}
                />
              </Elements>

              {this.state.error && <Message type="error" message={this.state.error} />}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(IsEventMember(observer(Checkout)));
