import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import type { GlobalContextTyping, FlowRouteProps } from '../../types';
import auth from '../../services/Auth';
import { validateEmailIsAvailable, validateEmail } from '../../services/Accounts';
import CustomerStore from '../../stores/CustomerStore';
import CustomerFormStore from '../../stores/CustomerFormStore';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import { url } from '../../utils/window';
import { AccessContext } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
  loginUrl?: string;
}

interface State {
  initialEmail?: string;
  tempEmail?: string;
  error?: string;
  loading: boolean;
  validating: boolean;
}

class Email extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initialEmail: undefined,
      tempEmail: '',
      error: undefined,
      loading: false,
      validating: false,
    };
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ tempEmail: e.target.value });

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(await this.isEmailValid())) {
      return;
    }
    this.setState(
      () => ({
        loading: true,
      }),
      async () => {
        if (this.emailHasChanged() || auth.signedOut()) {
          try {
            const res = await validateEmailIsAvailable(this.state.tempEmail!);

            if (res.status === 422) {
              throw new Error((await res.json())['email']);
            }

            if (res.status !== 200) {
              throw new Error('There has been an error validating your email. Please try again.');
            }

            CustomerFormStore.update('email', this.state.tempEmail!);
            this.nextPage();
          } catch (e) {
            let errorMessage = 'Failed to validate email.';

            if (e instanceof Error) {
              errorMessage = e.message;
            }

            this.setState(() => ({
              error: errorMessage,
              loading: false,
            }));
          }
        } else {
          this.nextPage();
        }
      }
    );
  };

  isEmailValid = async () => {
    this.setState(() => ({
      validating: true,
      loading: true,
    }));

    try {
      const response = await validateEmail(this.state.tempEmail!);

      const data = await response.json();

      if (data.errors) {
        const error = Array.isArray(data.errors)
          ? 'There was an error validating your email. Please try again.'
          : 'The provided email is invalid. Please confirm your entry and try again.';

        this.setState({ error });
      } else if (data.isValid === false) {
        this.setState({
          error: 'The provided email is invalid, please try another one.',
        });
      }

      return data.isValid ?? false;
    } catch (e) {
      console.error(e);

      this.setState(() => ({
        error: 'There was an error validating your email. Please try again.',
      }));

      return false;
    } finally {
      this.setState({
        validating: false,
        loading: false,
      });
    }
  };

  regexValidEmail = () => {
    const { tempEmail } = this.state;

    // fail fast, check if temp email exists
    if (!tempEmail || tempEmail === '') {
      return false;
    }

    // simple regex that that will allow stuff like test@test@test.com, but
    // will keep it fast for our users.
    return /\S+@\S+\.\S+/.test(tempEmail);
  };

  isEditing = () => url.param(this.props.location.search, 'edit') !== null && CustomerStore.id !== undefined;

  emailHasChanged = () =>
    this.state.tempEmail !== this.state.initialEmail && this.state.tempEmail !== CustomerStore.email;

  nextPage = () => {
    this.setState((state) => ({
      error: undefined,
      initialEmail: state.tempEmail,
    }));

    this.props.flow!(this.props.location.search);
  };

  render() {
    const customSubtitle = (
      <p className="text-center" data-testid="text-custom-subtitle">
        {'Already have an account? '}
        <Link
          className="tracker-link-email-login-20200619-102753"
          data-testid="lnk-login"
          to={this.props.loginUrl + this.props.location.search || `/account/login${this.props.location.search}`}
        >
          {'Login'}
        </Link>
      </p>
    );

    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          customSubtitle={customSubtitle}
          data-testid="input-email"
          error={this.state.error!}
          inputMode="email"
          key="email"
          label="Email"
          loading={this.state.loading}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          property="email"
          title="What is your email?"
          type="email"
          validating={this.state.validating}
          validExternally={this.regexValidEmail()}
          value={this.state.tempEmail!}
        />
      </>
    );
  }
}

export default Flow(AccessContext(observer(Email)));
