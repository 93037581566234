import { useEffect, useRef, useState } from 'react';
import IconLink from '../../components/IconLink';
import IconPhone from '../../components/IconPhone';
import IconX from '../../components/IconX';
import HorizontalRuleWithText from '../../components/HorizontalRuleWithText';
import useClickOutside from '../../utils/hooks/useClickOutside';
import { sharedLookCopyLinkBtnClickTrack, sharedLookTrack, sharedLookSmsBtnClickTrack } from '../../utils/metrics';
import { getMember } from '../../services/Events';
import MemberStore from '../../stores/MemberStore';
import { setCommunicationPreferences } from '../../services/Accounts';
import auth from '../../services/Auth';
import { Customer, ShareData } from '../../types';
import CopyToClipboard from 'react-copy-to-clipboard';
import IconCheck from '../../components/IconCheck';
import IconAlert from '../../components/IconAlert';
import { MessageMedium, MessageType } from '../../utils/CommunicationPreferences';

import './ShareModal.css';

type Props = {
  onClose: () => void;
  shareData?: ShareData;
};

const getCustomerData = async (): Promise<Customer> => {
  const res = await getMember(MemberStore.getSignedInMember()!.id!);

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const { data } = await res.json();

  if (!data?.member?.customer) {
    throw new Error('Failed to retrieve customer data.');
  }

  return data.member.customer;
};

export const sendShareLink = async (lookUrl: string, lookName: string) => {
  const { phone } = await getCustomerData();

  sharedLookTrack({
    phone,
    lookUrl,
    lookName,
  });
};

export const sendTextWithCommunicationPrefs = async (lookUrl: string, lookName: string) => {
  await setCommunicationPreferences(auth.user().id, {
    [MessageMedium.SMS]: {
      [MessageType.Updates]: true,
    },
  });

  await sendShareLink(lookUrl, lookName);
};

type InteractionResult = {
  type: 'success' | 'error';
  message: string;
};

const InteractionResultDisplay = (props: { result: InteractionResult }) => (
  <div className="flex flex-col items-center justify-start pb-32 md:pb-64">
    {props.result.type === 'success' ? (
      <IconCheck className="h-80 w-80 text-green" />
    ) : (
      <IconAlert className="h-80 w-80 text-red" />
    )}

    <p className="text-xs mt-16 text-center">{props.result.message}</p>
  </div>
);

const ShareModal = ({ shareData, onClose }: Props) => {
  const [isSendingLink, setIsSendingLink] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [interactionResult, setInteractionResult] = useState<InteractionResult | null>(null);

  const ref = useRef(null);

  const handleClose = () => {
    onClose();
  };

  useClickOutside(ref, handleClose);

  const displayErrorMessage = () =>
    setInteractionResult({
      type: 'error',
      message: 'An error occurred, please try again.',
    });

  const handleTextToMyselfClick = async () => {
    try {
      if (!shareData) {
        throw new Error(`Can't send text message because shareData is undefined`);
      }

      sharedLookSmsBtnClickTrack(shareData);

      setIsSendingLink(true);

      const { url, lookName } = shareData;

      await sendTextWithCommunicationPrefs(url, lookName);

      setInteractionResult({
        type: 'success',
        message: 'Link texted to you.',
      });
    } catch (e) {
      console.error(e);
      displayErrorMessage();
    } finally {
      setIsSendingLink(false);
    }
  };

  const handleCopyLinkClick = async () => {
    try {
      const customer = await getCustomerData();

      sharedLookCopyLinkBtnClickTrack({
        phone: customer.phone,
        ...shareData,
      });

      setInteractionResult({
        type: 'success',
        message: 'Link copied to clipboard.',
      });
    } catch (err) {
      console.error(err);
      displayErrorMessage();
    }
  };

  useEffect(() => {
    if (interactionResult && !isClosing) {
      setIsClosing(true);

      setTimeout(() => {
        setIsClosing(false);
        onClose();
      }, 1200);
    }
  }, [interactionResult, isClosing, onClose]);

  const transitionClasses = 'transition duration-100 delay-1000 opacity-0';

  return (
    <div
      className={`share-modal fixed left-0 top-0 z-30 h-full w-full pb-2/4 pt-0 ${isClosing ? transitionClasses : ''}`}
      ref={ref}
      onClick={(ev) => {
        ev.stopPropagation();
        handleClose();
      }}
    >
      <div
        className="border-1 relative z-30 mx-4 mt-1/4 flex h-full flex-col justify-evenly rounded border-solid bg-white p-8 pt-0"
        style={{ contain: 'content' }}
      >
        <div className="my-0 flex w-full justify-end self-start pt-8">
          <button className="text-xs text-gray-dark">
            Cancel
            <IconX style={{ cursor: 'pointer' }} className="text-xs ml-4 inline text-gray-dark" />
          </button>
        </div>

        <div className="mt-8 flex flex-grow flex-col justify-center">
          {interactionResult ? (
            <InteractionResultDisplay result={interactionResult} />
          ) : (
            <>
              <CopyToClipboard text={shareData?.url!}>
                <button
                  className="btn text-xs btn-info w-full uppercase md:text-sm"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    handleCopyLinkClick();
                  }}
                >
                  <IconLink className="cursor-pointer" />
                  Copy Link
                </button>
              </CopyToClipboard>

              <HorizontalRuleWithText text="or" className="text-sm items-center py-8 text-gray-dark" />

              <button
                className="btn text-xs btn-info w-full uppercase md:text-sm"
                disabled={isSendingLink}
                onClick={(ev) => {
                  ev.stopPropagation();
                  handleTextToMyselfClick();
                }}
              >
                <IconPhone style={{ cursor: 'pointer' }} />
                <span className="xl:hidden">Text Me</span>
                <span className="hidden xl:block">Text Me Link to Share</span>
              </button>

              <p className="mt-8 text-[10px] text-gray-dark">* Clicking this will opt you into SMS communication</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
