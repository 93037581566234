import React, { useEffect, useState } from 'react';
import { EventRouteProps, MemberCard as MemberCardType, Member } from '../../types';
import MemberCard from './members/components/MemberCard';
import { observer } from 'mobx-react';
import { IsOwner } from '../../utils/HOC/';
import PotentialMemberStore from '../../stores/PotentialMemberStore';
import MemberStore from '../../stores/MemberStore';
import PageHeader from '../../partials/PageHeader';
import NavBarSub from '../../partials/NavBarSub';
import Navbar from '../../partials/Navbar';
import { isParticipant, isNonParticipant, hasNonCancelledStandardOrders } from '../../utils/utils';
import EventStore from '../../stores/EventStore';
import PartyRoleStore from '../../stores/PartyRoleStore';
import { themeCopy } from '../../utils/theme';
import IconPlus from '../../components/IconPlus';
import { Transition } from '@headlessui/react';
import { pageFadeIn } from '../../utils/Component/Animations';
import HowItWorks from '../../partials/HowItWorks';

interface Props extends EventRouteProps<any> {}

const partyRolePlaceHolder = [
  'Groom',
  'Groomsmen',
  'Groomsmen',
  'Groomsmen',
  'Father of Bride',
  'Father of Groom',
  'Usher',
  'Ring Bearer',
];

const Members = (props: Props) => {
  const [memberCards, setMemberCards] = useState<MemberCardType[]>([]);

  useEffect(() => {
    // Create placeholder members by checking constant partyRolePlaceHolder
    // and what is in the PotentialMemberStore
    const placeHolderMemberCards: MemberCardType[] =
      EventStore.gtEventType!.id === 9
        ? partyRolePlaceHolder.reduce((acc: MemberCardType[], placeHolder, i) => {
            const participantMembers = PotentialMemberStore.potentialMembers.filter(
              (m) => isParticipant(m.partyRole!.name) && m.partyRole!.name.toLowerCase() === placeHolder.toLowerCase()
            );

            // Create placeholder member card object
            const placeHolderMemberCard: MemberCardType = {
              id: `${placeHolder}-${i}`,
              nickname: '',
              partyRoleName: placeHolder,
              isLocked: false,
              isPlaceHolder: true,
              isPaid: false,
              member: null,
            };

            // If there are no potential members on the event with
            // a partyRole with the partyRolePlaceHolder constant,
            // add it as a placeholder
            if (participantMembers.length === 0) {
              return [...acc, placeHolderMemberCard];
            }

            // If a potential member is found with a party
            // role that exists in partyRolePlaceHolder, then
            // determine how many placeholders should be displayed
            // against the partyRolePlaceHolder
            if (
              acc.filter((potentialMember) => potentialMember.partyRoleName.toLowerCase() === placeHolder.toLowerCase())
                .length <
              partyRolePlaceHolder.filter((partyRole) => partyRole === placeHolder).length - participantMembers.length
            ) {
              return [...acc, placeHolderMemberCard];
            }

            return acc;
          }, [])
        : [];

    setMemberCards([
      ...PotentialMemberStore.potentialMembers
        .slice()
        .sort((a, b) => {
          // Member cards with potential members should be sorted
          // according to the partyRolePlaceHolder constant. Roles that
          // don't exist in the constant should go to the bottom of the list
          if (partyRolePlaceHolder.indexOf(a.partyRole!.name) === -1) {
            return 1;
          }
          if (partyRolePlaceHolder.indexOf(b.partyRole!.name) === -1) {
            return -1;
          }
          return partyRolePlaceHolder.indexOf(a.partyRole!.name) - partyRolePlaceHolder.indexOf(b.partyRole!.name);
        })
        .filter((m) => !isNonParticipant(m))
        .map((m) => ({
          id: m.id!,
          nickname: m.nickname!,
          partyRoleName: m.partyRole!.name,
          isLocked: m.member !== null && cardIsLocked(m.member!),
          isPaid: Boolean(m.member && Boolean(m.member.isPaid)),
          member: m.member ? m.member : null,
          isPlaceHolder: false,
        })),
      // put all placeholder cards at the bottom of the list
      ...placeHolderMemberCards,
    ]);
  }, []);

  const cardIsLocked = (member: Member) => {
    if (member.isCreator) {
      return true;
    }

    if (hasNonCancelledStandardOrders(MemberStore.members.find((m) => m.id === member.id)!)) {
      return true;
    } else {
      return false;
    }
  };

  const deleteCard = (index: number) => {
    setMemberCards(memberCards.filter((m, i) => i !== index));
  };

  const addCard = () => {
    if (EventStore.gtEventType!.id! === 9) {
      setMemberCards(
        memberCards.concat({
          id: `Groomsmen-${memberCards.length}`,
          nickname: '',
          partyRoleName: 'Groomsmen',
          isLocked: false,
          isPlaceHolder: true,
          isPaid: false,
          member: null,
        })
      );
    } else {
      const partyRoleName = PartyRoleStore.partyRoles.filter((p) => p.gtEventType.id === EventStore.gtEventType!.id!)[0]
        .name!;
      setMemberCards(
        memberCards.concat({
          id: `${partyRoleName}-${memberCards.length - 1}`,
          nickname: '',
          partyRoleName: partyRoleName,
          isLocked: false,
          isPlaceHolder: true,
          isPaid: false,
          member: null,
        })
      );
    }
  };

  const addCardKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addCard();
    }
  };

  return (
    <>
      <Navbar />
      <div data-testid="eventflow-members" className="container">
        <div className="row">
          <div className="col-span-12 sm:col-span-10 sm:col-start-2">
            <div className="m-section mt-32 hidden sm:block">
              <NavBarSub />
            </div>

            <Transition {...pageFadeIn}>
              <PageHeader header="Members">
                <p>
                  Add and organize your renting party members. If someone needs to help manage this event,{' '}
                  <button
                    className="tracker-link-members-add_owner-20191029-133321 text-anchor"
                    onClick={() => props.history.push(`/event-flow/details${props.location.search}`)}
                  >
                    add them as an owner.
                  </button>{' '}
                  With 5 paid members your suit or tux rental is free.*
                </p>
              </PageHeader>

              <div className="row">
                <div className="col-span-12 lg:col-span-9 lg:col-start-4 xl:col-span-9 xl:col-start-4">
                  <div className="grid grid-cols-11 gap-x-8">
                    <div className="col-span-5">
                      <h3 className="text-h5 text-gray-dark">Name</h3>
                    </div>
                    <div className="col-span-5 md:col-span-4">
                      <h3 className="text-h5 text-gray-dark">Role</h3>
                    </div>
                  </div>

                  {memberCards.map((memberCard, i) => {
                    return <MemberCard key={memberCard.id} handleDelete={deleteCard} card={memberCard} cardIndex={i} />;
                  })}

                  <div className="mb-16 pt-16">
                    <div
                      onClick={() => addCard()}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => addCardKeydown(e)}
                      className="tracker-cta-members-add_member_row-20191029-133442 flex cursor-pointer items-center justify-center border-2 border-dashed border-gray-light p-16"
                      tabIndex={0}
                      role="button"
                    >
                      <span className="text-h5">New Row</span> <IconPlus className="ml-8" />
                    </div>
                  </div>
                </div>
              </div>
            </Transition>

            <div className="pt-32 xs:pt-64 sm:hidden">
              <NavBarSub />
            </div>

            <p className="text-xs mt-64 text-gray-dark">{themeCopy.memberDisclaimer[window.organization]}</p>
          </div>
        </div>
        <HowItWorks className="mt-256" />
      </div>
    </>
  );
};

export default IsOwner(observer(Members));
