import { ChangeEvent, ComponentType, PropsWithChildren, useEffect, useMemo, useReducer } from 'react';
import {
  DateOptions,
  DateOptionsWithEventDate,
  DateOptionsDto,
} from '../../event-form-flow/components/date-picker/utils';
import FormSelect from '../../components/FormSelect';
interface OnChangeCallback {
  (newDate: Date): any;
}

interface DateFormatter {
  (portionOfDate: number): number | string;
}

interface DateSelectionProps {
  eventDate?: Date;

  formatYear?: DateFormatter;
  formatMonth?: DateFormatter;
  formatDay?: DateFormatter;

  onChange: OnChangeCallback;

  dropdownComponent?: ComponentType<DateSelectionDropdownProps>;
  optionComponent?: ComponentType<DateSelectionOptionProps>;
}

export type DateSelectionDropdownProps = PropsWithChildren<{
  name: 'day' | 'month' | 'year';
  value: number;
  setValue: (value: number) => any;
}>;

interface DateSelectionOptionProps {
  value: number;
  formatter: DateFormatter;
}

interface FormState {
  day: number;
  month: number;
  year: number;
  daysInMonth: number[];
  monthsInYear: number[];
}

type FormStateAction =
  | {
      type: 'day' | 'month' | 'year';
      value: number;
    }
  | {
      type: 'replace';
      value: FormState;
    };

const getDateOptions = (eventDate?: Date): DateOptionsDto => {
  if (eventDate) {
    return new DateOptionsWithEventDate(eventDate);
  }

  return new DateOptions(new Date());
};

const DefaultOptionComponent = (props: DateSelectionOptionProps) => {
  const displayValue = props.formatter(props.value);

  return (
    <option key={props.value} value={props.value}>
      {displayValue}
    </option>
  );
};

const DefaultDropdownComponent = (props: DateSelectionDropdownProps) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    props.setValue(Number(e.target.value));
  };

  return (
    <FormSelect
      className="flex-grow"
      aria-label={props.name}
      key={props.name}
      name={props.name}
      data-testid={`date-select-${props.name}`}
      value={props.value}
      onChange={handleChange}
    >
      {props.children}
    </FormSelect>
  );
};

const getFormStateFromOptions = (options: DateOptionsDto, currentState?: FormState): FormState => {
  let day = currentState?.day ?? options.getDefaultDay(),
    month = currentState?.month ?? options.getDefaultMonth(),
    year = currentState?.year ?? options.getDefaultYear();

  if (currentState) {
    if (!options.getYears().includes(currentState.year)) {
      year = options.getDefaultYear();
    }

    if (!options.getMonthsInYear(year).includes(month)) {
      month = options.getDefaultMonth();
    }

    if (!options.getDaysInMonth(year, month).includes(day)) {
      day = options.getDefaultDay();
    }
  }

  const monthsInYear = options.getMonthsInYear(year);
  const daysInMonth = options.getDaysInMonth(year, month);

  return {
    day,
    month,
    year,
    daysInMonth,
    monthsInYear,
  };
};

/**
 * Presents the user with a date picker to specify the year, month, and day of
 * their event
 *
 * You can change the component / element rendered via the `dropdownComponent`
 * and/or `optionComponent` props. By default it will be `<select>` and `<option>`
 * respectively.
 *
 * The `onChange` prop can be used to get a `Date` object whenever the user
 * changes an aspect of the date. This can be stored for reference whenever
 * the user submits the form.
 *
 * If the user already has an event date that we want to be sure is available
 * to them in the date picker (i.e. it's a past date), supply the `eventDate`
 * prop.
 */
const DateSelection = (props: DateSelectionProps) => {
  const Dropdown = props.dropdownComponent ?? DefaultDropdownComponent;
  const Option = props.optionComponent ?? DefaultOptionComponent;

  const dateOptions = useMemo(() => getDateOptions(props.eventDate), [props.eventDate]);

  const [formState, dispatch] = useReducer((current: FormState, update: FormStateAction) => {
    if (update.type === 'replace') {
      return update.value;
    }

    let next = {
      ...current,
      ...{ [update.type]: update.value },
    };

    if (update.type !== 'day') {
      // If the user changed the year, we'll need to update the months they can choose
      if (next.year !== current.year) {
        next.monthsInYear = dateOptions.getMonthsInYear(next.year);

        // If the month they currently have selected isn't a valid selection anymore
        // we'll fallback to the first available month in that year
        if (!next.monthsInYear.includes(next.month)) {
          next.month = next.monthsInYear[0];
        }
      }

      // Since either the month or year changed, let's refresh the days to be safe
      next.daysInMonth = dateOptions.getDaysInMonth(next.year, next.month);

      // If the day they currently have selected isn't in that month, fallback to
      // the first available day within that month
      if (!next.daysInMonth.includes(next.day)) {
        next.day = next.daysInMonth[0];
      }
    }

    return next;
  }, getFormStateFromOptions(dateOptions));

  const { formatYear = (_: number) => _, formatMonth = (_: number) => _, formatDay = (_: number) => _ } = props;

  // replace the form state if the date options have changed, e.g.
  // if the user navigates from one event details page to another
  useEffect(() => {
    dispatch({
      type: 'replace',
      value: getFormStateFromOptions(dateOptions, formState),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOptions]);

  // emit a change event when the form changes to outer components
  useEffect(() => {
    const selectionAsDate = new Date(formState.year, formState.month - 1, formState.day);

    props.onChange(selectionAsDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const { day, month, year, monthsInYear, daysInMonth } = formState;

  return (
    <>
      <Dropdown key="month" name="month" value={month} setValue={(m) => dispatch({ type: 'month', value: m })}>
        {monthsInYear.map((m) => (
          <Option key={m} value={m} formatter={formatMonth} />
        ))}
      </Dropdown>

      <Dropdown key="day" name="day" value={day} setValue={(d) => dispatch({ type: 'day', value: d })}>
        {daysInMonth.map((d) => (
          <Option key={d} value={d} formatter={formatDay} />
        ))}
      </Dropdown>

      <Dropdown key="year" name="year" value={year} setValue={(y) => dispatch({ type: 'year', value: y })}>
        {dateOptions.getYears().map((y) => (
          <Option key={y} value={y} formatter={formatYear} />
        ))}
      </Dropdown>
    </>
  );
};

export default DateSelection;
