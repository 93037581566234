import {
  EXPERIMENT_FIT_PAGE,
  EXPERIMENT_FIT_PAGE_ORIGINAL,
  EXPERIMENT_FIT_PAGE_LANGUAGE_CHANGE,
  EXPERIMENT_LOOKS_PAGE_SWATCHES_BTN,
  EXPERIMENT_LOOKS_PAGE_SWATCH_BTN_ORIGINAL,
  EXPERIMENT_LOOKS_PAGE_SWATCH_BTN_ICON,
  EXPERIMENT_LOOKS_PAGE_SWATCH_BTN_STACK,
  EXPERIMENT_WELCOME_PACKET,
  EXPERIMENT_WELCOME_PACKET_ON,
  EXPERIMENT_LOOK_BUILDER_TEST,
  EXPERIMENT_LOOK_BUILDER_ORIGINAL,
  EXPERIMENT_LOOK_BUILDER_VARIATION,
} from '../constants/experiments';

/**
 * A map of A/B tests that we have code built around
 *
 * You can read more about what these values mean and how this file's organized
 * at [`documentation/experiments.md`](../../documentation/experiments.md).
 */
export const experiments = {
  [EXPERIMENT_FIT_PAGE]: {
    id: process.env.REACT_APP_OPTIMIZELY_FIT_PAGE_TEST ?? '',
    variations: {
      [EXPERIMENT_FIT_PAGE_ORIGINAL]: process.env.REACT_APP_OPTIMIZELY_FIT_PAGE_TEST_ORIGINAL ?? '',
      [EXPERIMENT_FIT_PAGE_LANGUAGE_CHANGE]: process.env.REACT_APP_OPTIMIZELY_FIT_PAGE_TEST_LANGUAGE_CHANGE ?? '',
    },
  },
  [EXPERIMENT_LOOKS_PAGE_SWATCHES_BTN]: {
    id: process.env.REACT_APP_OPTIMIZELY_LOOKS_PAGE_SWATCH_BUTTON_TEST ?? '',
    variations: {
      [EXPERIMENT_LOOKS_PAGE_SWATCH_BTN_ORIGINAL]:
        process.env.REACT_APP_OPTIMIZELY_LOOKS_PAGE_SWATCH_BUTTON_ORIGINAL ?? '',
      [EXPERIMENT_LOOKS_PAGE_SWATCH_BTN_ICON]: process.env.REACT_APP_OPTIMIZELY_LOOKS_PAGE_SWATCH_BUTTON_ICON ?? '',
      [EXPERIMENT_LOOKS_PAGE_SWATCH_BTN_STACK]: process.env.REACT_APP_OPTIMIZELY_LOOKS_PAGE_SWATCH_BUTTON_STACK ?? '',
    },
  },
  [EXPERIMENT_WELCOME_PACKET]: {
    id: process.env.REACT_APP_OPTIMIZELY_WELCOME_PACKET_TEST ?? '',
    variations: {
      [EXPERIMENT_WELCOME_PACKET_ON]: process.env.REACT_APP_OPTIMIZELY_WELCOME_PACKET_ON ?? '',
    },
  },
  [EXPERIMENT_LOOK_BUILDER_TEST]: {
    id: process.env.REACT_APP_OPTIMIZELY_LOOK_BUILDER_TEST ?? '',
    variations: {
      [EXPERIMENT_LOOK_BUILDER_ORIGINAL]: process.env.REACT_APP_OPTIMIZELY_LOOK_BUILDER_TEST_ORIGINAL,
      [EXPERIMENT_LOOK_BUILDER_VARIATION]: process.env.REACT_APP_OPTIMIZELY_LOOK_BUILDER_TEST_VARIATION,
    },
  },
} as const;

export type ExperimentName = keyof typeof experiments;

export type ExperimentToBuckets = {
  -readonly [K in ExperimentName]: VariationsOf<K> | null;
};

export type VariationsOf<K extends ExperimentName> = keyof (typeof experiments)[K]['variations'];

/**
 * Flow page experiment map value objects
 */
type PageVisibilityCondition<Name extends ExperimentName> = {
  conditions: Array<() => boolean>;
  forExperiment: Name;
  expectedVariation: VariationsOf<Name>;
};

export const welcomePacketOn = {
  conditions: [],
  forExperiment: EXPERIMENT_WELCOME_PACKET,
  expectedVariation: EXPERIMENT_WELCOME_PACKET_ON,
} as PageVisibilityCondition<typeof EXPERIMENT_WELCOME_PACKET>;
