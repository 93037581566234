import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FormSingleInput from '../../utils/Component/FormSingleInput';
import EventStore from '../../stores/EventStore';
import CustomerStore from '../../stores/CustomerStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import auth from '../../services/Auth';
import { AccessContext } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';
import { GlobalContextTyping, FlowRouteProps } from '../../types';
import InvitedMemberFormStore from '../../stores/InvitedMemberFormStore';
import PotentialMemberStore from '../../stores/PotentialMemberStore';
import { accountCreated, loggedIn } from '../../utils/metrics';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

interface State {
  lastName?: string;
  error?: string;
  saving: boolean;
}

class LastName extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      lastName: '',
      error: undefined,
      saving: false,
    };
  }

  componentDidMount() {
    this.setState({
      lastName: InvitedMemberFormStore.lastName,
    });
  }

  async registerOrUpdateCustomer() {
    try {
      const isUserRegistered = auth.signedIn();

      if (!isUserRegistered) {
        await CustomerStore.create(InvitedMemberFormStore.email!, InvitedMemberFormStore.password!);
      }

      await CustomerStore.update({
        id: window.gt.user.id,
        email: InvitedMemberFormStore.email,
        password: InvitedMemberFormStore.password,
        phone: InvitedMemberFormStore.phone!,
        firstName: InvitedMemberFormStore.firstName,
        lastName: InvitedMemberFormStore.lastName,
        isRegistrationComplete: true,
        primaryEventId: EventStore.event.id!,
      });

      if (!isUserRegistered) {
        await PotentialMemberStore.joinEvent(InvitedMemberFormStore.potentialMemberId!, window.gt.user.id);
      }

      accountCreated(CustomerStore.customer);

      loggedIn();
    } catch (e) {
      let errorMessage = 'Failed to register or update customer.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }
      console.error(e);

      throw new Error(errorMessage);
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ lastName: e.target.value });
  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({
      saving: true,
    });

    InvitedMemberFormStore.update('lastName', this.state.lastName);

    try {
      await this.registerOrUpdateCustomer();

      this.nextPage();
    } catch (e) {
      console.error(e);
      this.setState({
        error: 'Something went wrong when adding you to this event.',
      });
    }
  };

  nextPage = () => {
    this.setState({
      error: undefined,
      saving: false,
    });

    this.props.flow!(this.props.location.search);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <FormSingleInput
          key="last-name"
          property="lastName"
          label="Last Name"
          title="What is your last name?"
          value={this.state.lastName!}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          loading={this.state.saving}
          error={this.state.error ? 'There was a problem creating your account' : ''}
        />
      </>
    );
  }
}

export default Flow(AccessContext(observer(LastName)));
