import SwatchStack from '../../../components/SwatchStack';
import { Item } from '../../../types';
import { makeSwatchParamsFromItems } from '../../../utils/utils';

type LookCardSwatchButtonProps = {
  items: Item[];
};

const LookCardSwatchStackButton = ({ items }: LookCardSwatchButtonProps): JSX.Element => {
  return (
    <div className="relative ml-8 w-56">
      <button
        className="absolute bottom-0 left-1/2 flex -translate-x-1/2 scale-90 flex-col items-center duration-150 hover:scale-100 md:scale-100 md:hover:scale-110"
        onClick={() => (window.location.href = `/swatch/build?swatches=${makeSwatchParamsFromItems(items)}`)}
        id="swatch-button"
      >
        <SwatchStack items={items} swatchSize={40} swatchSpacing={2} />
        <label className="mt-2 whitespace-nowrap text-[10px] !leading-3 text-gray-dark xs:whitespace-normal md:whitespace-nowrap">
          Free Samples
        </label>
      </button>
    </div>
  );
};

export default LookCardSwatchStackButton;
