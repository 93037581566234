import Line from '../components/Line';
import { PropsWithChildren } from 'react';

type PageHeaderProps = PropsWithChildren<{
  header: string;
  className?: string;
}>;

const PageHeader = ({ header, className, children }: PageHeaderProps) => {
  return (
    <div className="row my-32 md:my-64">
      <div className="col-span-12 xs:col-span-5 lg:col-span-3">
        <h1 className={`normalcase text-h2-display ${className ?? ''}`}>{header}</h1>
        <Line className="xs:!mb-0" />
      </div>
      <div className="col-span-12 xs:col-span-7 lg:col-span-5">{children}</div>
    </div>
  );
};

export default PageHeader;
