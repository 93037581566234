import { observer } from 'mobx-react';
import { Dispatch, SetStateAction, SyntheticEvent, KeyboardEvent, useEffect } from 'react';
import { format, addDays } from 'date-fns';

import PreviewStore from '../../../../stores/look-builder/PreviewStore';
import SideCarStore from '../../../../stores/look-builder/SideCarStore';
import GalleryStore from '../../../../stores/look-builder/GalleryStore';
import { Product, Bundle, BlockoutDate } from '../../../../types';
import { isOptionDisabled, makeJacketMediaArray, areAllOptionsDisabled, inHTOFlow } from '../../../utils/utils';
import { getCurrentBlockOutDates, itemHasRetailBundle, itemIsComingSoon } from '../../../../utils/utils';

import GalleryItemConfirm from './GalleryItemConfirm';
import ItemHeader from './GalleryItemHeader';
import SeeMore from './GalleryItemSeeMoreBtn';
import IconX from '../../../../components/IconX';
import { Transition } from '@headlessui/react';

interface Props {
  item: Product | Bundle;
  isBundle?: boolean;
  modelToggle?: boolean;
  setAnimateDirection: Dispatch<SetStateAction<string>>;
}

const getFirstAvailableDate = (blockoutDates: BlockoutDate[]) => {
  const upcoming = getCurrentBlockOutDates(blockoutDates);

  const date = upcoming.filter((date) => date.availability === 'coming-soon').shift();

  const endDate = date?.endDate ?? date?.end_date;

  if (!endDate) {
    throw new Error('Called getFirstAvailableDate() without any qualifying blockout dates');
  }

  // we have to add two days to this because of something
  // to do with how the database saves the end date, since
  // we want to get the day after the last day of the blockout
  const datePlusTwo = addDays(new Date(endDate), 2);

  return format(datePlusTwo, 'MMM. Do, YYYY');
};

const GalleryItem = ({ item, isBundle, modelToggle, setAnimateDirection }: Props) => {
  // Whether the customer wants to purchase the given item
  const hasActiveRetailBundle = itemHasRetailBundle(item) && PreviewStore.isItemActive(item.retailBundle);

  // Whether the given item should be pre-selected
  const itemIsSelected = PreviewStore.isItemActive(item) || hasActiveRetailBundle;

  /**
   * `GalleryStore.radioSelection` defaults to `Rent`, so we'll trigger an update
   * when the component mounts to set it to `Buy` if:
   *
   * - The current gallery item is a rental bundle, and its retail variant is the
   *   item that's active in the look builder
   * - The current gallery item is a retail bundle that has no rental variant, and
   *   is the same item that's active in the look builder
   */
  useEffect(() => {
    if (itemIsSelected) {
      GalleryStore.setInitialRadioSelection(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productsAndBundle = PreviewStore.productsAndBundle;
  const radioSelection = GalleryStore.radioSelection;
  const sideCarCategory = SideCarStore.category;

  const handleKeyDown = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Enter') {
      if (!isOptionDisabled(item, radioSelection, productsAndBundle))
        PreviewStore.setActiveItem(sideCarCategory!, item);
    }
  };

  const imageCount = item!.media! && item!.media!.filter((x) => x.label!.includes('PDP')).length;

  const showAsUnavailable =
    areAllOptionsDisabled(item, productsAndBundle) ||
    // if item is active, check it with active radio value
    (itemIsSelected && isOptionDisabled(item, radioSelection, productsAndBundle)) ||
    // if item is not active, we need to check with the item's default radio value
    isOptionDisabled(item, GalleryStore.getInitialRadioSelectionValue(item), productsAndBundle);

  let retailBundleOrItem = item;

  if (radioSelection === 'Buy' && item.retailBundle) {
    retailBundleOrItem = item.retailBundle;
  }

  const showAsComingSoon = itemIsComingSoon(retailBundleOrItem);

  const media =
    isBundle && !modelToggle
      ? makeJacketMediaArray(item)
      : item!.media!
      ? item!.media!.filter((x) => x.label === 'PDP_Hero')
      : [];

  return (
    <div
      data-testid="lookbuilder-gallery-item"
      className={`group relative flex flex-col justify-between ${!itemIsSelected && 'cursor-pointer mouse:hover:z-20'}`}
      onTouchStart={() => {}}
    >
      <div
        className={`relative flex-grow overflow-hidden bg-white transition focus:outline-none ${
          showAsUnavailable
            ? 'cursor-auto'
            : itemIsSelected
            ? 'scale-[.95] cursor-auto shadow-gallery-item duration-150 ease-in-out'
            : 'cursor-pointer ease-out noMouse:group-active:scale-[.95] mouse:group-hover:scale-[.98] mouse:group-hover:bg-brand-lighter mouse:group-hover:shadow-gallery-item'
        }`}
        style={{ willChange: 'transform' }}
        tabIndex={0}
        role="button"
        aria-label={item.displayName}
        onClick={() => {
          GalleryStore.setInitialRadioSelection(item);
          GalleryStore.selectGalleryItem(item);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLImageElement>) => handleKeyDown(e)}
      >
        {media &&
          media!.map((x) => (
            <img
              key={x.url}
              id={`gallery-text-${item.id}`}
              className={`block w-full bg-white tracker-btn-look_builder-set-${sideCarCategory!.replace(/-/g, '_')}-${
                item.id
              }-20200508-111100 transition-transform ${showAsUnavailable ? 'opacity-30' : 'opacity-100'}`}
              src={`${x.url}?auto=format&fit=crop&fm=jpg&q=75&w=300${
                item.type!.toLowerCase() === 'suit' || item.type!.toLowerCase() === 'tuxedo' ? '&ar=5:7' : ''
              }`}
              alt={`${item.type!.toLowerCase()}`}
            />
          ))}

        <Transition
          show={itemIsSelected}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <button
            className={`btn btn-default absolute right-0 top-0 border-none bg-brand p-4 transition mouse:hover:bg-red tracker-btn-look_builder-unset-${sideCarCategory!.replace(
              /-/g,
              '_'
            )}-20200508-111100`}
            title="Deselect"
            aria-label="Deselect"
            onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
              PreviewStore.unsetActiveItem(sideCarCategory!);
              e.stopPropagation();
            }}
          >
            <IconX className="pointer-events-none" />
          </button>
        </Transition>

        <div>
          <div className="gallery-item-info relative ">
            {showAsUnavailable && !showAsComingSoon && (
              <div className="absolute top-0 z-10 flex -translate-y-full animate-appear items-center">
                <div className="m-8 -mt-16 bg-white p-8 xs:p-16 lg:m-16">
                  <h3 className="text-h6 mb-4">Unavailable</h3>
                  <p className="text-xs">
                    <a
                      href="/signup/email"
                      className="tracker-text-look_builder-unavailable_create_event-201215-120106 text-xs inline-block"
                    >
                      Create an event
                    </a>{' '}
                    to see if this item available for your date.
                  </p>
                </div>
              </div>
            )}

            {showAsComingSoon && (
              <div className="absolute top-0 z-10 flex -translate-y-full animate-appear items-center">
                <div className="m-8 -mt-16 bg-white p-8 xs:p-16 lg:m-16">
                  <h3 className="text-h6 mb-4">Coming Soon</h3>
                  <p className="text-xs">
                    This item will be available {getFirstAvailableDate(item.blockoutDates ?? item.blockout_dates ?? [])}
                    .{' '}
                    <a
                      href="/signup/email"
                      className="tracker-text-look_builder-unavailable_create_event-201215-120106 text-xs inline-block"
                    >
                      Create an event
                    </a>{' '}
                    with your date information to build this look.
                  </p>
                </div>
              </div>
            )}

            <div className="relative border-t border-gray-lighter  p-8">
              {!isOptionDisabled(item, radioSelection, productsAndBundle) && (
                <SeeMore setAnimateDirection={setAnimateDirection} item={item} />
              )}
              <ItemHeader className="text-xs" displayName={item.displayName} />

              <p className="text-xs mr-8 text-gray-dark">
                {item.isRetail || item.type === 'Purchase' ? 'Buy ' : 'Rent '}${item.cost}
                {itemHasRetailBundle(item) && !inHTOFlow() && ` / Buy $${item.retailBundle!.cost}`}
              </p>
            </div>

            <Transition
              show={itemIsSelected}
              enter="transition duration-150 ease-out"
              enterFrom="opacity-0 translate-y-100"
              enterTo="opacity-100"
              leave="transition duration-200 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 translate-y-100"
            >
              <div className={`absolute bottom-0 left-0 right-0 border-t border-gray-lighter bg-white p-8`}>
                <GalleryItemConfirm
                  buttonSize="sm"
                  item={item}
                  imageCount={imageCount}
                  setAnimateDirection={setAnimateDirection}
                />
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(GalleryItem);
