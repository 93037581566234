import React, { Component } from 'react';
import { observer } from 'mobx-react';
import EventStore from '../../stores/EventStore';
import InvitedMemberFormStore from '../../stores/InvitedMemberFormStore';
import FormFlowLogo from '../../utils/Component/FormFlowLogo';
import auth from '../../services/Auth';
import { login } from '../../services/Accounts';
import { GTUser, GlobalContextTyping, FlowRouteProps, Member } from '../../types';
import { AccessContext } from '../../utils/HOC/';
import Flow from '../../utils/HOC/Flow';
import { differenceInDays } from 'date-fns';
import { getMemberships } from '../../services/Events';
import { loggedIn } from '../../utils/metrics';
import FormInput from '../../components/FormInput';
import Line from '../../components/Line';
import Message from '../../components/Message';
import { Link } from 'react-router-dom';
import { inHTOFlow } from '../../look-builder/utils/utils';

interface Props extends FlowRouteProps<any> {
  globalContext?: GlobalContextTyping;
  signUpUrl?: string;
}

interface State {
  email?: string;
  password?: string;
  error?: string;
  loading: boolean;
}

class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (auth.signedIn()) {
      this.nextPage(auth.user());
    }

    this.state = {
      email: '',
      password: '',
      error: undefined,
      loading: false,
    };
  }

  handleChange = (field: keyof State, value: string | number | boolean | undefined) => {
    this.setState((state) => ({
      ...state,
      [field]: value,
    }));
  };

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState(() => ({
      error: undefined,
      loading: true,
    }));
    try {
      const response = await login(this.state.email!, this.state.password!);
      if (response.status === 200) {
        const gtUser = (await response.json()) as GTUser;
        auth.signIn(gtUser);

        // TODO: we need to do some logic based
        // on their event progress to throw them to different spots on the event flow

        loggedIn();

        this.nextPage(gtUser);
      } else {
        this.setState(() => ({
          error: 'Incorrect username and password',
          loading: false,
        }));
      }
    } catch (e) {
      console.error(e);
      this.setState(() => ({
        error: 'Error trying to sign you in. Please try again.',
        loading: false,
      }));
    }
  };

  nextPage = async (gtUser: GTUser) => {
    let outflowIndex = 0;

    if (!window.gt.user.primaryEventId) {
      outflowIndex = 1;
    } else if (window.gt.user.firstName === '' || window.gt.user.lastName === '') {
      outflowIndex = 2;
    }

    if (inHTOFlow(this.props.history.location.pathname) && window.gt.user.primaryEventId) {
      await EventStore.loadEvent(window.gt.user.primaryEventId);

      if (EventStore.event?.startDate && differenceInDays(new Date(EventStore.event.startDate), Date.now()) <= 60) {
        outflowIndex = 3;
      }
    }

    // If a customer already has an existing HTO that
    // hasn't been return redirect them to info screen
    if (inHTOFlow(this.props.history.location.pathname)) {
      const memberships = (await (await getMemberships()).json()).data.customer.members;

      const someHaveActiveHtos = memberships.some((m: Member) => !Boolean(m.isReturned) && Boolean(m.gtEvent?.isTrial));

      if (someHaveActiveHtos) {
        outflowIndex = 4;
      }
    }

    if (this.props.history.location.pathname.includes('/invited')) {
      return this.props.history.push(`/invited/join/${InvitedMemberFormStore.shortCode}`);
    }

    let params = new URLSearchParams(this.props.location.search);

    if (gtUser.primaryEventId) {
      params.set('eventId', gtUser.primaryEventId);
    }

    this.props.flow!(`?${params.toString()}`, outflowIndex);
  };

  handleSignUpClick = () => {
    if (this.props.history.location.pathname.includes('/invited')) {
      return this.props.history.push(
        `${this.props.signUpUrl}/${InvitedMemberFormStore.shortCode}` || `/signup/email${this.props.location.search}`
      );
    }
    return this.props.history.push(this.props.signUpUrl || `/signup/email${this.props.location.search}`);
  };

  render() {
    return (
      <>
        <FormFlowLogo />

        <div className="container">
          <div className="mx-auto max-w-sm">
            <h1 className="text-h2 text-center">Login</h1>

            <p className="mb-32 text-center">
              Don't have an account?&nbsp;
              <Link
                to="#"
                onClick={() => this.handleSignUpClick()}
                className="tracker-link-login-sign_up-200619-111519 cursor-pointer"
              >
                Sign Up
              </Link>
            </p>

            <form onSubmit={this.handleSubmit}>
              {this.state.error && <Message type="error" message={this.state.error} />}
              <FormInput
                autoFocus={true}
                className="mb-16 w-full"
                combineLabel
                inputMode="email"
                label="Email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange('email', e.target.value)}
                placeholder="Enter your email"
                required
                type="email"
                value={this.state.email}
              />
              <FormInput
                placeholder="Enter your password"
                className="mb-8 w-full"
                required
                type="password"
                label="Password"
                combineLabel
                value={this.state.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange('password', e.target.value)}
              />

              <p className="mb-32">
                <Link
                  to="/customer/forgot-password"
                  className="tracker-link-login-forgot_password-200619-111519 text-sm cursor-pointer"
                >
                  Forgot Password?
                </Link>
              </p>
              <button
                className="tracker-cta-login-login-200619-111519 btn btn-info w-full"
                type="submit"
                disabled={this.state.loading}
              >
                {this.state.loading ? <span>Loading&hellip;</span> : 'Login'}
              </button>
            </form>

            <div className="align-center flex justify-center">
              <Line />
              <div className="mx-16 mt-4 w-16 text-gray sm:mt-[20px]">or</div>
              <Line />
            </div>

            <button
              type="button"
              className="tracker-cta-login-sign_up-200619-111519 btn btn-default w-full"
              onClick={() => this.handleSignUpClick()}
            >
              Sign Up
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default Flow(AccessContext(observer(Login)));
