import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useHistory, RouteChildrenProps } from 'react-router';
import { Transition } from '@headlessui/react';

import { EXPERIMENT_LOOKS_PAGE_SWATCHES_BTN } from '../../../constants/experiments';
import MemberStore from '../../../stores/MemberStore';
import LookStore from '../../../stores/LookStore';
import EventStore from '../../../stores/EventStore';
import { Look, DeviceType, ShareData } from '../../../types';
import { getCost, lookIsLocked, isNonParticipantLook, formatCurrency, getDevice, isGentux } from '../../../utils/utils';
import LookPreview from '../../../look-builder/components/look-builder/preview/LookPreview';
import IconX from '../../../components/IconX';
import IconLock from '../../../components/IconLock';
import IconShare from '../../../components/IconShare';
import IconTrashcan from '../../../components/IconTrashcan';
import useOptimizelyData from '../../../utils/hooks/useOptimizelyData';
import LookCardSwatchIconButton from './LookCardSwatchIconButton';
import LookCardSwatchStackButton from './LookCardSwatchStackButton';
import Spinner from '../../../shared/components/Spinner';
import ShareModal from '../ShareModal';
import { getShareLookCode } from '../../../services/Events';
import { shareLookBtnClickTrack } from '../../../utils/metrics';

const WarningMessage = ({ ...props }) => {
  return (
    <Transition
      as={Fragment}
      show={props.isShowing}
      enter="transition duration-300"
      enterFrom="opacity-0 translate-y-full"
      enterTo="opacity-100"
      leave="transition duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0 translate-y-full"
    >
      <div className={`absolute bottom-0 z-10 bg-gray-lighter`}>
        <div className="p-16 pb-32">{props.children}</div>
      </div>
    </Transition>
  );
};

const getCostFromLook = (look: Look) => {
  const products = look.items ?? [];
  const bundles = look.bundles ?? [];

  return getCost([...products, ...bundles]);
};

const getSwatchButtonVariant = (optimizelyVariation: string, look: Look): JSX.Element => {
  switch (optimizelyVariation) {
    case 'icon':
      return <LookCardSwatchIconButton items={[...look.items!, ...look.bundles!]} />;
    case 'swatchStack':
      return <LookCardSwatchStackButton items={[...look.items!, ...look.bundles!]} />;
    default:
      return <></>;
  }
};

export const getShareLinkUrl = (code: string) => `${process.env.REACT_APP_ECOMM_URL}/l/${code}`;

/**
 * Returns the content of the text message we send to users who want to share
 * a look they've made
 *
 * **Note**: This content is currently used when users trigger the look sharing
 * feature on a mobile device, because in that case the native sharing feature
 * is used. For desktop users the message will be sent by Iterable, and marketing
 * controls the content of the message through its UI.
 */
export const getLookSharingSmsContent = (lookName: string, org: string, url: string) =>
  `Shared preview of ${lookName} from ${org} ${url}`;

enum MESSAGE_TYPES {
  CannotDeleteLastLook,
  CannotDeleteWithMembers,
}

type DeleteWarningMessageProps = {
  mType: MESSAGE_TYPES | null;
  location: RouteChildrenProps['location'];
  history: RouteChildrenProps['history'];
};

const DeleteWarningMessage = ({ mType, location, history }: DeleteWarningMessageProps) => {
  if (mType === MESSAGE_TYPES.CannotDeleteLastLook) {
    return <p className="text-sm">Cannot delete this look. There must be at least one look on this page.</p>;
  }

  return (
    <p className="text-sm">
      Cannot delete looks with members assigned. Visit{' '}
      <button
        onClick={() => history.push(`/event-flow/assign${location.search}`)}
        className="tracker-link-look_card-assign_page-20200619-103658 text-anchor text-sm"
      >
        assign page
      </button>{' '}
      to move members.
    </p>
  );
};

type Props = {
  look: Look;
};

const LookCard = ({ look }: Props): JSX.Element => {
  const { data, loaded } = useOptimizelyData();
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareData, setShareData] = useState<ShareData>();
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showEditWarning, setShowEditWarning] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [messageType, setMessageType] = useState<MESSAGE_TYPES | null>(null);
  const [cost, setCost] = useState(() => getCostFromLook(look));

  const hasCheckedOutMembers = lookIsLocked(look, MemberStore.members, window.gt.user.id);
  const hasMembers =
    (look.members && look.members.length > 0) ||
    (look.potentialMembers && look.potentialMembers.filter((m) => m.member === null).length > 0);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => setCost(getCostFromLook(look)), [look]);

  const generateCode = async () => {
    const lookId = look.id;
    const eventId = EventStore.id;

    if (!eventId) throw new Error('No Event Id.');

    const response = await getShareLookCode(eventId, lookId);

    if (response.status !== 200) {
      throw new Error(`Could not generate the share code.`);
    }

    const json = await response.json();

    return json.code;
  };

  const getShareData = async (): Promise<ShareData> => {
    const looks = EventStore.event.roles;

    const lookDetails = looks?.find((thisLook: Look) => thisLook.id === look.id);

    const lookId = look.id;
    const lookName = look.name ?? 'a look';

    const code = lookDetails?.shareLinkCode ? lookDetails.shareLinkCode : await generateCode();

    const orgId = window.gt.orgId;
    const orgName = orgId === 1 ? 'Generation Tux' : 'Menguin';

    const url = getShareLinkUrl(code);

    const text = getLookSharingSmsContent(lookName, orgName, url);

    return {
      url,
      text,
      lookId,
      lookName,
      title: orgName,
    };
  };

  const handleShowShareModal = async () => {
    if (typeof window !== 'undefined' && navigator?.share) {
      try {
        //Need to determine if we are actually on a mobile device and not dependent on screen sizes.
        const device = getDevice(navigator);

        if ([DeviceType.Phone, DeviceType.Tablet].includes(device.type)) {
          await navigator.share(shareData);
        } else {
          setShowShareModal(true);
        }
      } catch (e) {
        console.error(`Navigator Error: ${e}`);
      }
    } else {
      setShowShareModal(true);
    }
  };

  const handleModalClose = () => {
    setShowShareModal(false);
  };

  const handleShareButtonClick = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    try {
      const sData = await getShareData();
      if (sData) {
        setShareData(sData);
        shareLookBtnClickTrack(sData);

        if (!showShareModal) {
          handleShowShareModal();
        } else {
          setShowShareModal(false);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const showLock = () => {
    if (hasMembers || LookStore.looks.filter((look) => !isNonParticipantLook(look)).length === 1) {
      return true;
    }

    return false;
  };

  const setShowWarningAndMessage = () => {
    if (hasMembers) {
      setMessageType(MESSAGE_TYPES.CannotDeleteWithMembers);
    }

    if (LookStore.looks.filter((look) => !isNonParticipantLook(look)).length === 1) {
      setMessageType(MESSAGE_TYPES.CannotDeleteLastLook);
    }

    setShowDeleteWarning(!showDeleteWarning);
  };

  const handleDuplicateLook = () => {
    setIsDuplicating(true);
    LookStore.duplicateLook(EventStore.event!.id!, [...look.items!, ...look.bundles!]);

    // prevent another duplicate of the same look for a second
    setTimeout(() => {
      setIsDuplicating(false);
    }, 1500);
  };

  return (
    <>
      {!loaded ? (
        <Spinner className="h-full" type="minimal" />
      ) : (
        <div
          className={`LookCard shadow-lg ${
            hasCheckedOutMembers ? '' : 'hover:shadow-2xl'
          } group overflow-hidden bg-white duration-300`}
          style={{ willChange: 'transform' }}
        >
          {showShareModal && <ShareModal onClose={handleModalClose} shareData={shareData} />}
          <div className="relative">
            <div
              className={`
                tracker-cta-looks-edit_look_container-191111-112506
                block
                ${hasCheckedOutMembers ? 'cursor-auto' : 'cursor-pointer'}
            `}
              onClick={
                hasCheckedOutMembers
                  ? () => setShowEditWarning(!showEditWarning)
                  : () => history.push(`/event-flow/looks/build/${look.id}${location.search}`)
              }
            >
              <div className="relative z-20 flex flex-wrap items-start justify-between p-16 pb-0">
                <div>
                  <h3 className="text-h4 mb-4 text-gray-darker xs:mb-0">{`${look.name}`}</h3>
                  <p className="LookCard__cost text-sm text-gray-dark">{formatCurrency(cost)}</p>
                </div>

                {hasCheckedOutMembers ? (
                  <button className="btn btn-sm btn-default-outline px-8 py-4" title="Item Locked" aria-label="Locked">
                    <IconLock />
                  </button>
                ) : isGentux() ? (
                  <button
                    onClick={(ev) => {
                      handleShareButtonClick(ev);
                    }}
                    className="tracker-cta-looks-share_modal-240221-162446 btn btn-sm btn-info-outline px-8 py-4"
                  >
                    <IconShare className="mb-4" /> Share
                  </button>
                ) : (
                  <button className="tracker-cta-looks-edit_look-20191029-132127 btn btn-sm btn-info-outline px-8 py-4">
                    Edit Look
                  </button>
                )}
              </div>
              <div
                className={`relative z-10 flex scale-[.97] items-start justify-center px-8 ${
                  hasCheckedOutMembers ? '' : 'group-hover:scale-100'
                } duration-300`}
                style={{ willChange: 'transform' }}
              >
                <LookPreview items={[...look.items!, ...look.bundles!]} />
              </div>
            </div>

            <WarningMessage isShowing={showDeleteWarning}>
              <div className="flex justify-end">
                <IconX
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowWarningAndMessage()}
                  className="tracker-link-look_card-close_delete_warning-200619-103947"
                />
              </div>
              <DeleteWarningMessage mType={messageType} location={location} history={history} />
            </WarningMessage>

            <WarningMessage isShowing={showEditWarning}>
              <div className="flex justify-end">
                <IconX
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowEditWarning(!showEditWarning)}
                  className="tracker-link-look_card-close_edit_warning-20200619-103658"
                />
              </div>
              <p className="text-sm">Cannot edit looks with members who have checked out.</p>
            </WarningMessage>
          </div>

          <hr className="border-gray-light" />
          <div className="relative z-10 flex justify-between bg-gray-lighter p-8 xs:p-16">
            <button
              onClick={showLock() ? () => setShowWarningAndMessage() : () => LookStore.deleteLook(look.id)}
              title={showLock() ? 'Cannot delete, members are assigned to this look' : 'Careful careful.'}
              className="
          tracker-cta-looks-delete_look-20191029-131946 btn btn-sm btn-default-outline mr-8 flex
            !px-8
          "
              aria-label={showLock() ? 'Locked' : 'Delete'}
            >
              {showLock() ? <IconLock /> : <IconTrashcan />}
            </button>
            <button
              disabled={isDuplicating}
              onClick={() => handleDuplicateLook()}
              className="
          tracker-cta-looks-duplicate_look-20191029-131933 btn btn-sm btn-default-outline grow
            !px-0
          "
            >
              {isDuplicating ? 'Duplicating...' : 'Duplicate'}
            </button>
            {data?.[EXPERIMENT_LOOKS_PAGE_SWATCHES_BTN] &&
              getSwatchButtonVariant(data[EXPERIMENT_LOOKS_PAGE_SWATCHES_BTN]!, look)}
          </div>
        </div>
      )}
    </>
  );
};

export default observer(LookCard);
