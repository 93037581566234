import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import EventStore from '../../stores/EventStore';
import MemberStore from '../../stores/MemberStore';
import LookStore from '../../stores/LookStore';
import CustomerStore from '../../stores/CustomerStore';
import ItemStore from '../../stores/ItemStore';
import DrawerStore from '../../stores/DrawerStore';
import PreviewStore from '../../stores/look-builder/PreviewStore';
import { Transition } from '@headlessui/react';

import { Look, EventRouteProps, GlobalContextTyping, Member, Item } from '../../types';

import { sortLooks, outfitWithoutBlockedItems } from '../../utils/utils';
import { AccessContext, IsOwner, EmptyOutfitsRedirect } from '../../utils/HOC/';

import Navbar from '../../partials/Navbar';
import PageHeader from '../../partials/PageHeader';
import NavBarSub from '../../partials/NavBarSub';

import Spinner from '../../shared/components/Spinner';
import LookCard from './Optimizely/LookCard';
import { lookAdded, lookisDeletable } from '../../utils/metrics';
import IconPlus from '../../components/IconPlus';
import Message from '../../components/Message';
import { pageFadeIn } from '../../utils/Component/Animations';
import HowItWorks from '../../partials/HowItWorks';
import InfoCallout from '../../components/InfoCallout';

const NON_PARTICIPANT = 'Non-participant';

interface Props extends EventRouteProps<any> {
  globalContext?: GlobalContextTyping;
}

const Looks = (props: Props) => {
  const [showLoading, setShowLoading] = useState(true);
  const [showNewLookLoading, setShowNewLookLoading] = useState(false);
  const [showError, setShowError] = useState('');

  useEffect(() => {
    (async () => {
      PreviewStore.reset();
      DrawerStore.resetPages();

      // We will preload the items for the browse items page.
      if (ItemStore.cachedItemsByCategory['jacket-and-pants'].length === 0) {
        try {
          await ItemStore.fetchAndCache();
        } catch (e) {
          console.error((e as Error).message);
        }
      }

      const outfit = window.localStorage.getItem('outfit');

      if (outfit !== '' && outfit !== null) {
        // Before adding look, remove all blocked items
        if (outfitWithoutBlockedItems(JSON.parse(outfit).outfit).length > 0) {
          await addOutfitBuilderLook(outfitWithoutBlockedItems(JSON.parse(outfit).outfit));
        }

        window.localStorage.removeItem('outfit');
        PreviewStore.reset();
      }

      // We want to remove any numbered looks that have no items and members.
      // If any role was deleted, refetch eventDetails to re-render.
      await LookStore.deleteEmptyLooks();
      setShowLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOutfitBuilderLook = async (outfit: Item[]) => {
    if (MemberStore.members) {
      const signedInMember: Member = MemberStore.members.find((x) => x.accountId! === CustomerStore.customer.id!)!;
      if (signedInMember) {
        if (
          signedInMember.role &&
          signedInMember.role.name &&
          !signedInMember.role.name.includes(NON_PARTICIPANT) &&
          (!signedInMember.role.items ||
            !signedInMember.role.bundles ||
            (signedInMember.role.items.length === 0 && signedInMember.role.bundles.length === 0))
        ) {
          try {
            await LookStore.addItemsToLook(signedInMember.role, outfit);
          } catch (e) {
            setShowError('There was a problem saving your outfit.');
            return;
          }
        } else {
          try {
            await LookStore.addLookAndItems(EventStore.event.id!, outfit);
          } catch (e) {
            setShowError('There was a problem saving your outfit.');
            return;
          }
        }
      }
    }
  };

  const addNewLook = async () => {
    setShowNewLookLoading(true);

    try {
      const newLookName = LookStore.nextOutfitName();
      await LookStore.addLook(props.eventId, newLookName);

      const look = LookStore.findLookByName(newLookName);
      if (look && look.id) {
        setShowNewLookLoading(false);
        lookAdded(look);
        props.history.push(`/event-flow/looks/build/${look.id}${props.location.search}`);
      } else {
        throw new Error();
      }
    } catch (e) {
      setShowNewLookLoading(true);
      setShowError('There was an error preparing your look. Please try again.');
    }
  };

  const looks: Array<Look> = (LookStore.looks || []).filter(
    (look: Look) => look.name!.toLowerCase().indexOf('non-participant') === -1
  );

  const eventType = EventStore.event?.gtEventType?.name;

  return (
    <>
      <Navbar />
      <div data-testid="eventflow-looks" className="container">
        <div className="row">
          <div className="col-span-12 sm:col-span-10 sm:col-start-2">
            <div className="m-section mt-32 hidden sm:block">
              <NavBarSub />
            </div>
            <Transition {...pageFadeIn}>
              <PageHeader header="Looks">
                {eventType === 'Wedding' ? (
                  <InfoCallout
                    title={'Everyone gets their outfit 2 weeks before the wedding.'}
                    subtext={'Plenty of time for adjustments if needed.'}
                    img={{
                      src: 'https://gentux.imgix.net/1718209352_FingerStringAnimation.gif',
                      alt: 'Finger String Animation',
                    }}
                  />
                ) : (
                  <p>{'Build and customize your looks.'}</p>
                )}
              </PageHeader>

              <div className="row gap-y-32">
                {showLoading ? (
                  <div className="col-span-6 mb-32 xs:col-span-4">
                    <Spinner className="h-full" type="minimal" />
                  </div>
                ) : (
                  (!looks || looks.length === 0) && (
                    <div className="col-span-12">
                      <h3 className="normal-case text-h1-display mb-32">Get started by adding looks.</h3>
                    </div>
                  )
                )}

                {showError && (looks === undefined || looks.length === 0) && (
                  <Message type="error" message={showError} />
                )}

                {!showLoading &&
                  looks &&
                  looks.length > 0 &&
                  looks
                    .filter((look: Look) => !lookisDeletable(look))
                    .slice()
                    .sort(sortLooks)
                    .map((look: Look, index) => (
                      <div
                        className={`col-span-6 xs:col-span-4 lg:col-span-3 ${
                          looks.length < 3 && index === 0 ? 'lg:col-start-4' : ''
                        }`}
                        key={look.name}
                      >
                        <LookCard look={look} />
                      </div>
                    ))}

                <div className="col-span-6 flex xs:col-span-4 lg:col-span-3">
                  <button
                    onClick={() => addNewLook()}
                    disabled={showNewLookLoading}
                    className="tracker-cta-looks-add_look-20191029-131824 btn btn-info flex flex-grow flex-col items-center gap-y-16 py-64"
                  >
                    <IconPlus />
                    Add&nbsp;Look
                  </button>
                </div>
              </div>
            </Transition>

            <div className="pt-32 xs:pt-64 sm:hidden">
              <NavBarSub />
            </div>
          </div>
        </div>
        <HowItWorks className="mt-256" />
      </div>
    </>
  );
};

export default EmptyOutfitsRedirect(IsOwner(AccessContext(observer(Looks))));
