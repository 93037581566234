import { useEffect, useRef, useState } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';

import TitleBar from './look-builder/TitleBar';
import BuilderPreview from './look-builder/preview/BuilderPreview';
import BuilderPreviewMobile from './look-builder/preview/BuilderPreviewMobile';
import List from './look-builder/list/List';
import Gallery from './look-builder/gallery/Gallery';
import GalleryDetails from './look-builder/gallery/GalleryDetails';
import PreviewExploded from './look-builder/preview/PreviewExploded';

import PreviewStore from '../../stores/look-builder/PreviewStore';
import SideCarStore from '../../stores/look-builder/SideCarStore';

import { hasCurrentBlockOutDates, makeSwatchParamsFromItems } from '../../utils/utils';
import { getCurrentUrl } from '../../utils/window';
import { SideCar } from '../../types';
import { JACKET_AND_PANTS } from '../data/categories';
import { isCategoryValidInCurrentFlow } from '../utils/utils';
import Loading from '../../utils/Component/Loading';
import screens from '../../tailwind/screens.json';
import Message from '../../components/Message';
import IconSwatch from '../../components/IconSwatch';

interface Props {
  error: string;
  isLoading: boolean;
  isSubmitting: boolean;
  displayLookCost?: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
}

const LookBuilder = (props: Props) => {
  const { error, isLoading, isSubmitting, handleSubmit, handleClose } = props;
  const didMountRef = useRef(false);

  const [animateDirection, setAnimateDirection] = useState('left');

  const [mediaQuery, setMediaQuery] = useState(window.matchMedia(`(min-width: ${screens.xs})`));

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      setMediaQuery(window.matchMedia(`(min-width: ${screens.xs})`));
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [mediaQuery]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSideCarFromQueryString();
  }, []);

  useEffect(() => {
    document.documentElement.classList.add('bg-gray-light');
    return () => {
      document.documentElement.classList.remove('bg-gray-light');
    };
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      setSideCarFromQueryString();
    } else {
      didMountRef.current = true;
    }
  });

  reaction(
    () => SideCarStore.sidecar,
    (sidecar) => {
      setQueryStringFromSideCar(sidecar);
    }
  );

  reaction(
    () => PreviewStore.lookPreview,
    (lookPreview) => {
      PreviewStore.addToHistory(lookPreview);
    }
  );

  /**
   *  Hide the swatches button if there are no associated swatches
   *  or if all swatches on the items have blockout dates
   */
  const shouldRenderSwatchesButton = () => {
    if (SideCarStore.car !== 'list') return false;
    if (window.location.toString().includes('customize')) return false;

    return PreviewStore.productsAndBundle.some(
      (item) => item.swatch?.catalogNumber && !hasCurrentBlockOutDates(item.swatch.blockoutDates ?? [])
    );
  };

  const setSideCarFromQueryString = () => {
    let params = new URLSearchParams(window.location.search);
    const sidecar = params.get('sidecar');

    if (sidecar === 'list' || sidecar === 'gallery' || sidecar === 'details') {
      if (
        JSON.stringify(SideCarStore.sidecar) !==
        JSON.stringify({
          car: sidecar,
          category: params.get('category'),
          id: params.get('itemId') ? Number(params.get('itemId')) : null,
        })
      ) {
        SideCarStore.set({
          car: sidecar,
          category:
            params.get('category') && isCategoryValidInCurrentFlow(params.get('category')!)
              ? params.get('category')
              : JACKET_AND_PANTS,
          id: params.get('itemId') ? Number(params.get('itemId')) : null,
        });
      }
    } else {
      SideCarStore.set({
        car: 'list',
        category: null,
        id: null,
      });
    }
  };

  const setQueryStringFromSideCar = (sideCar: SideCar) => {
    let params = new URLSearchParams(window.location.search);

    if (
      JSON.stringify(sideCar) !==
      JSON.stringify({
        car: params.get('sidecar'),
        category: params.get('category'),
        id: params.get('itemId') ? Number(params.get('itemId')) : null,
      })
    ) {
      if (params.get('sidecar') !== sideCar.car) {
        params.set('sidecar', sideCar.car);
      }

      if (params.get('category') !== sideCar.category) {
        if (sideCar.category) {
          params.set('category', sideCar.category);
        } else {
          params.delete('category');
        }
      }

      if (Number(params.get('itemId')) !== sideCar.id) {
        if (sideCar.id) {
          params.set('itemId', sideCar.id.toString());
        } else {
          params.delete('itemId');
        }
      }

      window.history.pushState(null, '', `${getCurrentUrl()}?${params}`);
    }
  };

  const renderSideCar = () => {
    if (SideCarStore.car === 'list') {
      return (
        <div>
          <List
            animateDirection={animateDirection}
            setAnimateDirection={setAnimateDirection}
            mediaQueryXsMatches={mediaQuery.matches}
            displayLookCost={props.displayLookCost}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            handleClose={handleClose}
          />
        </div>
      );
    }

    if (SideCarStore.car === 'gallery') {
      return (
        <Gallery
          animateDirection={animateDirection}
          setAnimateDirection={setAnimateDirection}
          mediaQueryXsMatches={mediaQuery.matches}
        />
      );
    }

    if (SideCarStore.car === 'details') {
      return (
        <GalleryDetails
          animateDirection={animateDirection}
          setAnimateDirection={setAnimateDirection}
          mediaQueryXsMatches={mediaQuery.matches}
        />
      );
    }
  };

  if (error !== '') {
    return <Message type="error" message={error} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {PreviewStore.previewExploded ? (
        <PreviewExploded mediaQueryXsMatches={mediaQuery.matches} />
      ) : (
        <div data-testid="lookbuilder" className="mx-auto max-w-[2240px] px-16 sm:px-32">
          <div className="row">
            <div
              className="
                col-span-12 
                flex 
                justify-center
                self-start
                py-16
                text-center
                xs:pointer-events-none 
                xs:sticky 
                xs:top-0
                xs:z-[500]
                xs:col-span-5
                xs:py-32
                sm:col-span-6
                md:col-span-5
                lg:col-span-6
                lg:py-64
              "
            >
              {SideCarStore.category && !mediaQuery.matches ? (
                <BuilderPreviewMobile
                  setAnimateDirection={setAnimateDirection}
                  mediaQueryXsMatches={mediaQuery.matches}
                />
              ) : (
                <BuilderPreview mediaQueryXsMatches={mediaQuery.matches} />
              )}
            </div>
            <div
              className={`
                col-span-12
                xs:col-span-7
                sm:col-span-6
                ${SideCarStore.car !== 'list' ? 'md:col-span-7' : 'md:col-span-6'}
                ${SideCarStore.car !== 'list' ? 'lg:col-span-6' : 'lg:col-span-5'}
                ${SideCarStore.car !== 'list' ? `pt-80` : 'xs:pt-32 lg:pt-64'}
                pb-32
            `}
              style={{ transition: 'width 0.3s ease-out' }}
            >
              <div className={`LookBuilder ${SideCarStore.car === 'list' ? 'max-w-[550px]' : ''}`}>
                <div className="flex items-center justify-between">
                  {SideCarStore.car === 'list' && (
                    <>
                      <TitleBar displayLookCost={props.displayLookCost} />
                      {shouldRenderSwatchesButton() && (
                        <button
                          className=" tracker-cta-look_builder-order_swatches-20200514-145708 btn btn-sm btn-default-outline"
                          onClick={() =>
                            (window.location.href = `/swatch/build?swatches=${makeSwatchParamsFromItems(
                              PreviewStore.productsAndBundle
                            )}`)
                          }
                        >
                          Order Swatches <IconSwatch className="ml-2" />
                        </button>
                      )}
                    </>
                  )}
                </div>
                {renderSideCar()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(LookBuilder);
