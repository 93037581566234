import IconSwatch from '../../../components/IconSwatch';
import { Item } from '../../../types';
import { makeSwatchParamsFromItems } from '../../../utils/utils';

type SwatchStackProps = {
  items: Item[];
};

const LookCardSwatchIconButton = ({ items }: SwatchStackProps): JSX.Element => {
  return (
    <div className="relative ml-8 w-56">
      <button
        className="absolute left-1/2 top-8 flex -translate-x-1/2 scale-90 flex-col items-center duration-150 hover:scale-100 md:scale-100 md:hover:scale-110"
        onClick={() => (window.location.href = `/swatch/build?swatches=${makeSwatchParamsFromItems(items)}`)}
        id="swatch-button"
      >
        <IconSwatch />
        <label className="whitespace-nowrap text-[10px] !leading-3 text-gray-dark xs:whitespace-normal md:whitespace-nowrap">
          Free Samples
        </label>
      </button>
    </div>
  );
};

export default LookCardSwatchIconButton;
