import { Component } from 'react';
import type { ChangeEvent, InputHTMLAttributes } from 'react';

import FormNavigation from './FormNavigation';
import FormInput from '../../components/FormInput';

type Props = {
  backEnabled?: boolean;
  customSubtitle?: JSX.Element;
  disclaimer?: any;
  error?: string;
  inputMode?: InputHTMLAttributes<HTMLInputElement>['inputMode'];
  label: string;
  loading?: boolean;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (ev: ChangeEvent<HTMLFormElement>) => void;
  placeholder?: string;
  property: string;
  required?: boolean;
  requiredLength?: number;
  subtitle?: string;
  title?: string;
  type?: string;
  validating?: boolean;
  validExternally?: boolean;
  value: string;
} & DataTestIdProp;

class FormSingleInput extends Component<Props> {
  // This is typescripts fault for causing mutation here -_-
  private _input: any = undefined;

  componentDidMount() {
    if (this._input !== undefined) {
      this._input.focus();
    }
  }

  isDisabled = (value: string | null, requiredLength: number, validExternally: boolean, loading: boolean): boolean =>
    value === null || value === '' || value?.length < requiredLength || validExternally === false || loading === true;

  render() {
    const {
      backEnabled = true,
      customSubtitle,
      disclaimer,
      error = '',
      inputMode,
      label,
      loading = false,
      onChange,
      onSubmit,
      placeholder = '',
      property,
      required = true,
      requiredLength = 1,
      subtitle = '',
      title = '',
      type = 'text',
      validating = false,
      validExternally = true,
      value,
    } = this.props;

    return (
      <div className="container" data-testid={this.props['data-testid']}>
        <div className="mx-auto max-w-sm space-y-32">
          {title !== '' && (
            <>
              <h1 className="text-h2-display text-center" data-testid="fsi-text-title">
                {title}
              </h1>
              {subtitle && (
                <p className="text-center" data-testid="fsi-text-subtitle">
                  {subtitle}
                </p>
              )}
            </>
          )}

          {customSubtitle !== undefined && customSubtitle}

          <form onSubmit={onSubmit} className="w-full space-y-32">
            <FormInput
              autoFocus={true}
              className="w-full"
              combineLabel
              data-testid={'fsi-input'}
              disabled={validating}
              errorMessage={error}
              id={property}
              inputMode={inputMode}
              label={label}
              name={property}
              onChange={onChange}
              pattern={type === 'number' ? '[0-9]*' : undefined}
              placeholder={placeholder || title}
              required={required}
              type={type}
              value={value}
            />

            {disclaimer}

            <FormNavigation
              backEnabled={backEnabled}
              disabled={this.isDisabled(value, requiredLength, validExternally, loading)}
              validating={validating}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default FormSingleInput;
