import type { SyntheticEvent } from 'react';
import IconArrowRight from '../../components/IconArrowRight';
import BackButton from './BackButton';

type Props = {
  backEnabled?: boolean;
  className?: string;
  disabled?: boolean;
  next?: (ev: SyntheticEvent<HTMLButtonElement>) => void;
  trackerClassBack?: string;
  trackerClassNext?: string;
  validating?: boolean;
};

const FormNavigation = (props: Props) => {
  const {
    next,
    disabled = false,
    backEnabled = true,
    validating = false,
    className = '',
    trackerClassNext,
    trackerClassBack,
  } = props;

  return (
    <div className={`flex flex-row-reverse gap-8 ${className}`}>
      <button
        className={`${
          trackerClassNext ? trackerClassNext : 'tracker-cta-form_nav-next-200619-115650'
        } btn btn-info grow`}
        data-testid="btn-submit"
        type="submit"
        onClick={next || undefined}
        disabled={disabled}
      >
        {validating ? `Validating...` : `Next`} <IconArrowRight />
      </button>

      {backEnabled && (
        <BackButton
          className="w-auto"
          data-testid="btn-back"
          disabled={validating}
          iconOnly
          trackerClass={trackerClassBack}
        />
      )}
    </div>
  );
};

export default FormNavigation;
