import { useRef, useState } from 'react';
import { type Image } from '../types';

type InfoCalloutProps = {
  title: string;
  subtext: string;
  img: Image;
};

const InfoCallout = ({ title, subtext, img }: InfoCalloutProps) => {
  const [gifLoaded, setGifLoaded] = useState<boolean>(false);
  const timestampRef = useRef<number>(Date.now());

  const titleAnimation = {
    duration: '350ms',
    delay: '1000ms',
  };

  const subtextAnimation = {
    duration: '700ms',
    delay: '500ms',
  };

  const imgUrl = `${img.src}?${timestampRef.current}`;
  const animateText = gifLoaded;

  return (
    <div className="InfoCallout">
      <div className="flex gap-[25px]">
        <div className="InfoCallout_icon flex-shrink-0">
          <img
            className="h-[80px] w-[52px] xs:h-[88px] xs:w-[57.43px]"
            src={imgUrl}
            alt={img.alt ?? ''}
            onLoad={() => setGifLoaded(true)}
            onError={() => setGifLoaded(false)}
          />
        </div>
        {gifLoaded && (
          <div className="InfoCallout_info flex-shrink-1 w-[300px] xs:w-[320px]">
            <h2
              style={{
                animationDuration: titleAnimation.duration,
                animationDelay: titleAnimation.delay,
              }}
              className={`${
                animateText ? `animate-slideInFromLeft` : ''
              } font-['Roboto Condensed'] text-h2 normal-case mb-8 font-bold leading-[26px] text-gray-darker xs:leading-[30px]`}
            >
              {title}
            </h2>
            <p
              style={{
                animationDuration: subtextAnimation.duration,
                animationDelay: `calc(${titleAnimation.delay} + ${subtextAnimation.delay})`,
              }}
              className={`${
                animateText ? `animate-slideInFromTop` : ''
              } text-xs font-['Roboto'] font-normal leading-[13px] tracking-[0.24px] text-gray-darker xs:leading-[17px] xs:tracking-[0.3px]`}
            >
              {subtext}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoCallout;
