import PreviewStore from '../../../stores/look-builder/PreviewStore';
import { observer } from 'mobx-react';
import { formatCurrency } from '../../../utils/utils';

type Props = {
  displayLookCost?: boolean;
};
const TitleBar = (props: Props) => {
  return (
    <div className="flex items-center">
      <h2 className="text-h3">{PreviewStore.lookName}</h2>
      {props?.displayLookCost && (
        <>
          <span className="LookBuilder__price1 mx-16">-</span>
          <span className="LookBuilder__price1 text-sm text-gray-darker">{formatCurrency(PreviewStore.getCost())}</span>
        </>
      )}
    </div>
  );
};

export default observer(TitleBar);
