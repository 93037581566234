import { endOfMonth, addDays, parse, getDaysInMonth, format, isWednesday, differenceInDays } from 'date-fns';

import { range } from '../../../utils/utils';

/**
 * Returns a boolean indicating whether the given date is less than two weeks away
 */
export const isDateLessThanTwoWeeksAway = (date: string, now = new Date()) => {
  const then = parse(date);

  // convert the dates to UTC and have them both start at midnight to ignore
  // things like timezone differences and get an accurate number of calendar
  // days between the two dates
  const nowAsUtc = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const thenAsUtc = Date.UTC(then.getFullYear(), then.getMonth(), then.getDate());

  return new Date(thenAsUtc) < addDays(nowAsUtc, 14);
};

/**
 * Returns a boolean indicating whether we're fifteen days or less away from the
 * first Wednesday of the year
 *
 * This is used to determine whether we should increment the default year that's
 * selected when the event date picker is loaded
 */
export const shouldIncrementYear = (years: number[], now = new Date()) => {
  const nextYear = years[1];

  const dayOfFirstWed = getFirstWednesdayOfYear(nextYear, now);

  if (dayOfFirstWed === -1) {
    // this shouldn't occur since we only ever call this function with years that
    // are relative to the date at runtime, so this is here for posterity's sake
    throw new Error(`No Wednesday in Jan. ${years[1]} is two weeks or more away (relative to ${now})`);
  }

  const formattedDate = `${nextYear}-01-${withLeadingZero(dayOfFirstWed)}`;

  const daysUntilFirstWednesday = differenceInDays(formattedDate, now.getTime());

  return daysUntilFirstWednesday < 16;
};

/**
 * Returns the first Wednesday of the given year that's valid for scheduling an
 * event
 *
 * Any Wednesdays that are less than two weeks away are considered invalid, so
 * on Dec. 29, 2020, that upcoming Wednesday (Jan 5th) would be invalid and
 * Jan. 13, 2021 would be the returned day
 */
export const getFirstWednesdayOfYear = (year: number, now = new Date()) => {
  const validDays = range(1, getDaysInMonth(`${year}-01`)).filter(day => {
    let date = `${year}-01-${withLeadingZero(day)}`;

    return !isDateLessThanTwoWeeksAway(date, now);
  });

  return (
    validDays.find(day => {
      return isWednesday(`${year}-01-${withLeadingZero(day)}`);
    }) ?? -1
  );
};

export const withLeadingZero = (monthOrDay: number) => `0${monthOrDay}`.slice(-2);

export interface DateOptionsDto {
  options: Map<number, Map<number, number[]>>;

  getYears(): number[];
  getDefaultYear(): number;
  getDefaultMonth(): number;
  getDefaultDay(): number;

  /**
   * @throws {Error} if the given year is not a valid key
   */
  getMonthsInYear(year: number): number[];

  /**
   * @throws {Error} if the given year or month are not valid keys
   */
  getDaysInMonth(year: number, month: number): number[];
}

export class DateOptions implements DateOptionsDto {
  protected defaultYear = 1970;
  protected defaultMonth = 1;
  protected defaultDay = 1;

  protected selectionOptions: Map<number, Map<number, number[]>>;

  constructor(protected now = new Date()) {
    const years = [now.getFullYear(), now.getFullYear() + 1, now.getFullYear() + 2];

    this.defaultYear = shouldIncrementYear(years, now) ? years[2] : years[1];

    this.defaultDay = getFirstWednesdayOfYear(this.defaultYear, now);

    this.selectionOptions = this.getPossibleOptions(years, now);
  }

  get options() {
    return this.selectionOptions;
  }

  getMonthsInYear(year: number) {
    const months = this.selectionOptions.get(year);

    if (!months) {
      throw new Error(`${year} is not a valid year for selection`);
    }

    return Array.from(months.keys());
  }

  getDaysInMonth(year: number, month: number) {
    if (!this.getMonthsInYear(year).includes(month)) {
      throw new Error(`${year}-${withLeadingZero(month)} is not a valid month for selection`);
    }

    return this.selectionOptions.get(year)?.get(month) ?? [];
  }

  getYears() {
    return Array.from(this.selectionOptions.keys());
  }

  /**
   * The default month to show in the date picker - this is always January
   */
  getDefaultMonth() {
    return this.defaultMonth;
  }

  /**
   * The default year to show in the date picker
   *
   * This will be the year after the present, unless we're less than sixteen
   * days away from the first or second Wednesday of that year, in which case
   * it will be the year after next
   */
  getDefaultYear() {
    return this.defaultYear;
  }

  /**
   * The default day (of the month) to show in the date picker
   *
   * This will be the first or second Wednesday of the next coming year, whichever
   * one is at minimum two weeks away. If both of those dates fall within less than
   * two weeks, this will be the first Wednesday of the year after next.
   */
  getDefaultDay() {
    return this.defaultDay;
  }

  protected getPossibleOptions(years: number[], now: Date) {
    const yearsToMonths = years.map(year => this.getYearToMonths(year, now));

    const validYears = yearsToMonths.filter(([_, months]) => months.size > 0);

    return new Map(validYears);
  }

  protected getYearToMonths(year: number, now: Date): [number, Map<number, number[]>] {
    const months = range(1, 12).map(month => this.getMonthToDays(year, month, now));

    const validMonths = months.filter(([_, days]) => days.length > 0);

    return [year, new Map(validMonths)];
  }

  protected getMonthToDays(year: number, month: number, now: Date): [number, number[]] {
    const monthDate = `${year}-${withLeadingZero(month)}`;

    const days = range(1, getDaysInMonth(monthDate)).filter(day => {
      const dateWithDay = `${monthDate}-${withLeadingZero(day)}`;

      return !isDateLessThanTwoWeeksAway(dateWithDay, now);
    });

    return [month, days];
  }
}

export class DateOptionsWithEventDate extends DateOptions {
  constructor(protected eventDate: Date, now = new Date()) {
    super(now);

    const year = eventDate.getFullYear();
    const month = eventDate.getMonth() + 1;
    const day = eventDate.getDate();

    const datesInYear = this.selectionOptions.get(year) ?? new Map<number, number[]>();
    const daysInMonth = datesInYear.get(month) ?? [];

    if (!daysInMonth.includes(day)) {
      daysInMonth.push(day);

      daysInMonth.sort((a, b) => a - b);
    }

    datesInYear.set(month, daysInMonth);

    // re-sort the months in case it wasn't already in the map
    this.selectionOptions.set(year, this.getDatesSortedByKey(datesInYear));
    // re-sort the years in case the year wasn't already set
    this.selectionOptions = this.getDatesSortedByKey(this.selectionOptions);

    this.defaultYear = year;
    this.defaultMonth = month;
    this.defaultDay = day;
  }

  protected getDatesSortedByKey(dates: Map<number, any>) {
    const entries = [...dates.entries()];

    entries.sort((a, b) => {
      const [keyA] = a;
      const [keyB] = b;

      return keyA - keyB;
    });

    return new Map(entries);
  }
}

const defaultOptions = new DateOptions();

export const YEARS = defaultOptions.getYears();

export const DEFAULT_YEAR = defaultOptions.getDefaultYear();
export const DEFAULT_MONTH = defaultOptions.getDefaultMonth();
export const DEFAULT_DAY = defaultOptions.getDefaultDay();

// add 14 days because we can only select events more than 14 days away
export const isMonthInPast = (date: string, now = new Date()) => endOfMonth(date) < addDays(now, 14);

// find the first day of the month for the currently selected year
// this will allow us to find the last day of month and see if that month has passed
export const isMonthNotSelectable = (year: number, month: number, now = new Date()) => {
  if (!year) {
    return false;
  }
  return isMonthInPast(`${year}-${withLeadingZero(month)}-01`, now);
};

export const getFirstSelectableMonth = (year: number, now = new Date()) => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(month => !isMonthNotSelectable(year, month, now))[0];
};

export const getFirstSelectableDay = (year: number, month: number, now = new Date()) => {
  const day = daysInMonth(year, month).find(d => !isDayNotSelectable(d, now));
  return day !== undefined ? Number(format(day, 'D')) : 1;
};

export const isDayNotSelectable = (monthDay: string, now = new Date()) => isDateLessThanTwoWeeksAway(monthDay, now);

export const daysInMonth = (year: number, month: number) => {
  const numberOfDays = getDaysInMonth(format(`${year}-${withLeadingZero(month)}`, 'YYYY-MM'));

  return range(1, numberOfDays).map(day => {
    let date = `${year}-${withLeadingZero(month)}-${withLeadingZero(day)}`;

    return format(date, 'YYYY-MM-DD');
  });
};

export const monthIsTooSoon = (month: number, year: number, now = new Date()) =>
  month < getFirstSelectableMonth(year, now);

export const dayIsTooSoon = (month: number, day: number, year: number, now = new Date()) =>
  month === getFirstSelectableMonth(year, now) &&
  getFirstSelectableDay(year, getFirstSelectableMonth(year, now), now) > day;

export const formatMonth = (month: number, year: number) => format(`${year}-${withLeadingZero(month)}`, 'MMM');

export const formatDay = (day: string) => format(day, 'D');

// Select Options
export const monthOptions = (year: number, now = new Date()) =>
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(month => !isMonthNotSelectable(year, month, now));

export const dayOptions = (year: number, month: number, now = new Date()) =>
  daysInMonth(year, month).filter(day => !isDayNotSelectable(day, now));

export const hasUsedDefaultEventDate = (day: number, month: number, year: number, defaults = defaultOptions) =>
  day === defaults.getDefaultDay() && month === defaults.getDefaultMonth() && year === defaults.getDefaultYear();

export const getAbbreviationForMonth = (month: number) => {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';
  }

  throw new Error(`Couldn't find an abbreviation for month #${month}`);
};
