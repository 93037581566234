type Props = {
  className?: string;
};

const IconShare = ({ className }: Props) => {
  return (
    <svg
      viewBox="0 0 20 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={`text-gold h-[16px] w-[16px] stroke-current ${className ?? ''}`}
    >
      <title>Share Icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 6L8.5 11L7.5 11L7.5 6L8.5 6Z" fill="black" />
      <path d="M12 6L8 1L4 6L12 6Z" fill="black" />
      <path d="M5.77778 9H3V14H13V9H10.2222" />
    </svg>
  );
};

export default IconShare;
