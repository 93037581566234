import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import ItemStore from '../../stores/ItemStore';
import LookStore from '../../stores/LookStore';
import PreviewStore from '../../stores/look-builder/PreviewStore';
import MemberStore from '../../stores/MemberStore';

import { EventRouteProps, GlobalContextTyping, Look, Bundle, Item } from '../../types';
import { lookIsLocked } from '../../utils/utils';
import { AccessContext, IsOwner } from '../../utils/HOC';
import { lookSaved } from '../../utils/metrics';

import LookBuilder from '../components/LookBuilder';

interface RouteParams {
  lookId: string;
}

interface Props extends EventRouteProps<RouteParams> {
  globalContext?: GlobalContextTyping;
}

const LookEdit = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [look, setLook] = useState<Look | undefined>(undefined);

  const { history, match, eventId } = props;

  useEffect(() => {
    (async () => {
      if (!ItemStore.itemsFetched) {
        try {
          await ItemStore.fetchAndCache();
        } catch (e) {
          setError('Something Went Wrong');
        }
      }

      const lookId = match.params.lookId;

      if (lookId == null) {
        history.goBack();
        return;
      }

      const look = LookStore.findLook(lookId);

      if (!look) {
        // If the look doesn't exist or if it was deleted because it was empty, go to looks page.
        history.goBack();
        return;
      }

      if (lookIsLocked(look, MemberStore.members, window.gt.user.id)) {
        return props.history.replace(`/event-flow/looks/locked/${lookId}?eventId=${props.eventId}`);
      }

      if (PreviewStore.lookPreview.filter((preview) => preview.activeItem !== null).length === 0) {
        if (look.items && look.items.length > 0) {
          PreviewStore.setProducts(look.items);
        }
        if (look.bundles && look.bundles.length > 0) {
          PreviewStore.setBundle(look.bundles[0]);
        }
      }

      setLook(look);
      PreviewStore.setLookName(look.name!);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLookItems = () => (look ? look.items! : []);
  const getLookBundles = () => (look ? look.bundles! : []);

  const getProductsToAdd = () =>
    PreviewStore.products
      .filter(
        (i: Item) =>
          getLookItems().find(
            (lookItem: Item) => i.category!.toLowerCase() === lookItem.category!.toLowerCase() && i.id === lookItem.id
          ) === undefined
      )
      .map((i) => i.id);

  const getProductsToDelete = () =>
    getLookItems()
      .filter(
        (lookItem: Item) =>
          PreviewStore.products.find(
            (i: Item) => i.category!.toLowerCase() === lookItem.category!.toLowerCase() && i.id === lookItem.id
          ) === undefined
      )
      .map((i) => i.id);

  const getBundlesToDelete = () => {
    const currentLookBundle = getLookBundles()[0];
    if (currentLookBundle) {
      const lookPreviewBundle = PreviewStore.bundle;
      if (lookPreviewBundle) {
        return currentLookBundle.id !== lookPreviewBundle.id ? currentLookBundle.id : undefined;
      }
      return currentLookBundle.id;
    }
    return undefined;
  };

  const getBundlesToAdd = () => {
    const toAdd = [PreviewStore.bundle].filter(
      (i: Bundle) => getLookBundles().find((lookItem: Item) => i && i.id === lookItem.id) === undefined
    )[0];

    return toAdd === null || toAdd === undefined ? undefined : toAdd.id;
  };

  const handleSubmit = async () => {
    if (lookIsLocked(look, MemberStore.members, window.gt.user.id)) {
      return props.history.replace(`/event-flow/looks/locked/${look!.id}?eventId=${props.eventId}`);
    }

    setIsSubmitting(true);
    setError('');
    try {
      await LookStore.updateOutfitItems(
        look!.id,
        getProductsToAdd(),
        getProductsToDelete(),
        getBundlesToAdd(),
        getBundlesToDelete()
      );

      lookSaved(PreviewStore.productsAndBundle, eventId, false);
      PreviewStore.reset();
      setIsSubmitting(false);

      history.push(`/event-flow/looks?eventId=${eventId}`);
    } catch (e) {
      let errorMessage = 'Failed to update outfit items.';

      if (e instanceof Error) {
        errorMessage = e.message;
      }

      console.error(e);
      setIsSubmitting(false);
      setError(errorMessage);
    }
  };

  const handleClose = () => {
    PreviewStore.reset();
    history.push(`/event-flow/looks?eventId=${eventId}`);
  };

  return (
    <LookBuilder
      error={error}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      displayLookCost
    />
  );
};

export default IsOwner(AccessContext(observer(LookEdit)));
